import React from 'react';
import { Row, Col, Typography, Card, Statistic } from 'antd';
import { geti18nText } from '@nybble/nyreact';

const { Title } = Typography;
interface ITempCard {
    temp?: number;
    humidity?: number;
    uv?: number;
    airPressure?: number;
    rainfall?: number;
    solarRadiation?: number;
}
const TempCard: React.FC<ITempCard> = ({
    temp = 0,
    humidity = 0,
    uv = 0,
    airPressure = 0,
    rainfall = 0,
    solarRadiation = 0,
}) => {
    const styles: any = {
        row: {
            alignItems: 'center',

            width: '100%',
            backgroundImage: 'linear-gradient(to bottom right, #87C1FF, #93c7ff)',
        },

        statistic: {
            margin: '20px',
        },
    };

    return (
        <React.Fragment>
            <Card style={styles.row} /*onClick={() => setParName(null)}*/>
                <Row>
                    <Col style={styles.statistic}>
                        <Statistic
                            title={geti18nText('weatherStateDetails.widget.title.temp')}
                            value={temp}
                            valueStyle={{
                                //color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: 'white',
                            }}
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                            suffix={'°C'}
                        />
                        <Statistic
                            title={geti18nText('weatherStateDetails.widget.title.humidity')}
                            value={humidity}
                            valueStyle={{
                                //color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: 'white',
                            }}
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                            suffix={'%'}
                        />
                        <Statistic
                            title={geti18nText('weatherStateDetails.widget.title.UVIntensity')}
                            value={uv}
                            valueStyle={{
                                //color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: 'white',
                            }}
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                        />
                    </Col>
                    <Col style={styles.statistic}>
                        <Statistic
                            title={geti18nText('weatherStateDetails.widget.title.airPressure')}
                            value={airPressure}
                            valueStyle={{
                                //color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: 'white',
                            }}
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                            suffix={'HPa'}
                        />
                        <Statistic
                            title={geti18nText('weatherStateDetails.widget.title.rainfall')}
                            value={rainfall}
                            valueStyle={{
                                //color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: 'white',
                            }}
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                            suffix={'mm/s'}
                        />
                        <Statistic
                            title={geti18nText('weatherStateDetails.widget.title.solarRadiation')}
                            value={solarRadiation}
                            valueStyle={{
                                //color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: 'white',
                            }}
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                            suffix={'W/m2'}
                        />
                    </Col>
                </Row>
            </Card>
        </React.Fragment>
    );
};

export default TempCard;
