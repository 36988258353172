import React, { useState } from 'react';
import { Row, Col, Form, Input, Divider, Descriptions } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { geti18nText, NyDataEdit } from '@nybble/nyreact';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import FormatNumber from '../../number-formatter';

const PayloadWeatherStateHistoryEdit = (props: any) => {
    const [editHeader, setEditHeader] = useState('Payload');
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState(null);
    const [item, setItem] = useState<any>(null);

    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    function setValues(dataForm: any) {
        setEditHeader(dataForm.id);
        setDataForm(dataForm.id);

        delete dataForm.active;
        setItem(dataForm);
        form.setFieldsValue(dataForm);
    }

    return (
        <NyDataEdit
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            url={CONSTANTS_REQ.DEVICE.PAYLOAD_WEATHER_STATE_HISTORY}
            setValues={setValues}
            form={form}
            width={900}
            goBack={() => history.goBack()}
            paramsId={id}
            hideActivationButtons={true}
            hideSubmitButton={true}
            {...props}
        >
            <Row gutter={24}>
                <Col span={24}>
                    <Divider orientation="left">{geti18nText('weatherStatePayloadHistory.table.title')}</Divider>
                    {item && item.deviceConsumption && (
                        <Descriptions column={2}>
                            <Descriptions.Item label={geti18nText('weatherStatePayloadHistory.table.windDirection')}>
                                {item['deviceConsumption']['avgWindDirection']} °
                            </Descriptions.Item>

                            <Descriptions.Item label={geti18nText('weatherStatePayloadHistory.table.windSpeed')}>
                                {item['deviceConsumption']['avgWindSpeed']} m/s
                            </Descriptions.Item>

                            <Descriptions.Item label={geti18nText('weatherStatePayloadHistory.table.temp')}>
                                {item['deviceConsumption']['temp']} °C
                            </Descriptions.Item>

                            <Descriptions.Item label={geti18nText('weatherStatePayloadHistory.table.humidity')}>
                                {item['deviceConsumption']['humidity']} %
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('weatherStatePayloadHistory.table.airPressure')}>
                                {item['deviceConsumption']['airPressure']} HPa
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('weatherStatePayloadHistory.table.solarRadiation')}>
                                {item['deviceConsumption']['solarRadiation']}W/m2
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('weatherStatePayloadHistory.table.UVIntensity')}>
                                {item['deviceConsumption']['uv_intensity']}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('weatherStatePayloadHistory.table.lux')}>
                                {item['deviceConsumption']['lux']}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('weatherStatePayloadHistory.table.noise')}>
                                {item['deviceConsumption']['avgNoise']}
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('weatherStatePayloadHistory.table.pm_10')}>
                                {item['deviceConsumption']['pm_10']} ug/m3
                            </Descriptions.Item>
                            <Descriptions.Item label={geti18nText('weatherStatePayloadHistory.table.pm_25')}>
                                {item['deviceConsumption']['pm_25']} ug/m3
                            </Descriptions.Item>
                        </Descriptions>
                    )}

                    <Divider />

                    <Form.Item label={'payload'} name="payload">
                        <Input />
                    </Form.Item>
                    <Divider orientation="left">{geti18nText('payloaEnergyHistory.table.payload.info.title')}</Divider>
                    {item && item.payloadInfo && (
                        <Descriptions column={2}>
                            <Descriptions.Item label={'antenna'}>{item['payloadInfo']['antenna']}</Descriptions.Item>

                            <Descriptions.Item label={'rssi'}>{item['payloadInfo']['rssi']}</Descriptions.Item>
                            <Descriptions.Item label={'payloadLength'}>
                                {item['payloadInfo']['payloadLength']}
                            </Descriptions.Item>
                            <Descriptions.Item label={'channel'}>{item['payloadInfo']['channel']}</Descriptions.Item>

                            <Descriptions.Item label={'dataRateSpreadFactor'}>
                                {item['payloadInfo']['dataRateSpreadFactor']}
                            </Descriptions.Item>
                            <Descriptions.Item label={'code'}>{item['payloadInfo']['code']}</Descriptions.Item>
                            <Descriptions.Item label={'codeRate'}>{item['payloadInfo']['codeRate']}</Descriptions.Item>

                            <Descriptions.Item label={'dataRateModulation'}>
                                {item['payloadInfo']['dataRateModulation']}
                            </Descriptions.Item>

                            <Descriptions.Item label={'frequency'}>
                                {item['payloadInfo']['frequency']}
                            </Descriptions.Item>

                            <Descriptions.Item label={'size'}>{item['payloadInfo']['size']}</Descriptions.Item>

                            <Descriptions.Item label={'port'}>{item['payloadInfo']['port']}</Descriptions.Item>

                            <Descriptions.Item label={'size'}>{item['payloadInfo']['size']}</Descriptions.Item>

                            <Descriptions.Item label={'rfChain'}>{item['payloadInfo']['rfChain']}</Descriptions.Item>
                            <Descriptions.Item label={'dataRateBandwidth'}>
                                {item['payloadInfo']['dataRateBandwidth']}
                            </Descriptions.Item>
                            <Descriptions.Item label={'loraSnr'}>{item['payloadInfo']['loraSnr']}</Descriptions.Item>
                            <Descriptions.Item label={'crcStatus'}>
                                {item['payloadInfo']['crcStatus']}
                            </Descriptions.Item>
                        </Descriptions>
                    )}

                    <Divider />
                </Col>
            </Row>
        </NyDataEdit>
    );
};

export default PayloadWeatherStateHistoryEdit;
