import React, { useEffect, useState } from 'react';
import { Layout, Input, Menu, Result, Button } from 'antd';
import { Route, Switch, useHistory, useLocation, Redirect } from 'react-router-dom';
import { mainRoutes } from './routes';
import {
    PieChartOutlined,
    GlobalOutlined,
    FileSearchOutlined,
    LogoutOutlined,
    UserOutlined,
    ClusterOutlined,
    DesktopOutlined,
    EditOutlined,
    IdcardOutlined,
    TeamOutlined,
    GroupOutlined,
    StockOutlined,
    SubnodeOutlined,
    BarChartOutlined,
    MobileOutlined,
    ShoppingCartOutlined,
    DeploymentUnitOutlined,
    SwapOutlined,
    DashboardOutlined,
    SettingOutlined,
    AlertOutlined,
    CloudOutlined,
    CloudUploadOutlined,
    CloudDownloadOutlined,
    AppstoreOutlined,
} from '@ant-design/icons';
import './index.scss';
import logo from '../../assets/img/logo.png';
import logoMini from '../../assets/img/logo_mali.png';

import SubMenu from 'antd/lib/menu/SubMenu';
import { APPLICATION_KEY, CONSTANTS_REQ, DeviceCategoryType, EnvironmentType } from '../../utils/Constants';
import {
    geti18nText,
    NyLanguageSelector,
    NyLayoutHeader,
    NyLayoutMenu,
    NySession,
    NyUtils,
    NyRequestResolver,
    RESPONSE,
} from '@nybble/nyreact';
import { GetEnum, getUserCategoryType } from '../../utils/Enums';
import LayoutMenu from './layout-menu/layout-menu';

const { Content } = Layout;
const { Search } = Input;

const menu = [
    {
        key: 'menu.dashboard',
        path: '/',
        icon: <PieChartOutlined />,
        i18n: 'menu.dashboard',
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER', 'ROLE_CUSTOMER_SPECTATOR'],
        type: ['WATERMETER', 'LIGHTS', 'ENERGYMETER', 'SHARED', 'WEATHER'],
        order: 1,
    },
    {
        key: 'menu.dmazone',
        icon: <GroupOutlined />,
        i18n: 'menu.dmazone',
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        type: ['WATERMETER_ONLY'],
        order: 2,
        submenu: [
            {
                key: 'menu.devicegroup',
                path: '/device-groups',
                icon: <GroupOutlined />,
                i18n: 'menu.devicegroup',
                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
            },
            {
                key: 'menu.dma',
                path: '/dma',
                icon: <BarChartOutlined />,
                i18n: 'menu.dma',
                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
            },
        ],
    },
    {
        key: 'menu.device',
        path: '/devices',
        icon: <DesktopOutlined />,
        i18n: 'menu.device',
        type: ['WATERMETER_ONLY'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 3,
    },
    {
        key: 'menu.lights',
        path: '/lights',
        icon: <DesktopOutlined />,
        i18n: 'menu.lights',
        type: ['LIGHTS_ONLY'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 3,
    },
    {
        key: 'menu.energy_meter',
        path: '/energyMeter',
        icon: <DesktopOutlined />,
        i18n: 'menu.device',
        type: ['ENERGYMETER_ONLY'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 3,
    },
    {
        key: 'menu.weather_meter',
        path: '/weatherMeter',
        icon: <DesktopOutlined />,
        i18n: 'menu.device',
        type: ['WEATHER_ONLY'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 3,
    },
    {
        key: 'menu.lights.group',
        path: '/lightsGroup',
        icon: <GroupOutlined />,
        i18n: 'menu.lights.group',
        type: ['LIGHTS_ONLY'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 4,
    },
    {
        key: 'menu.energy_meter.group',
        path: '/energyMetersGroup',
        icon: <GroupOutlined />,
        i18n: 'menu.energy_meter.group',
        type: ['ENERGYMETER_ONLY'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 4,
    },
    {
        key: 'menu.weather_meter.group',
        path: '/weatherMetersGroup',
        icon: <GroupOutlined />,
        i18n: 'menu.weather_meter.group',
        type: ['WEATHER_ONLY'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 4,
    },
    {
        key: 'menu.weather_alarm_settingsp',
        path: '/alarmSettings',
        icon: <AlertOutlined />,
        i18n: 'menu.weather_alarm_settings',
        type: ['WEATHER_ONLY'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 4,
    },
    {
        key: 'menu.watermeter.dashboard',
        icon: <DashboardOutlined />,
        i18n: 'menu.watermeter',
        role: ['ROLE_CUSTOMER_SPECTATOR'],
        type: ['WATERMETER'],
        order: 3,
        notificationType: DeviceCategoryType.WATERMETER,
        path: '/waterMeterDashboard',
    },

    {
        key: 'menu.lights.dashboard',
        icon: <DesktopOutlined />,
        i18n: 'menu.lights',
        type: ['LIGHTS'],
        role: ['ROLE_CUSTOMER_SPECTATOR'],
        order: 4,
        notificationType: DeviceCategoryType.LIGHTS,
        root: true,
        path: '/lightsDashboard',
    },

    {
        key: 'menu.energy_meter.dashboard',
        icon: <DesktopOutlined />,
        i18n: 'menu.energy_meter',
        type: ['ENERGYMETER'],
        role: ['ROLE_CUSTOMER_SPECTATOR'],
        order: 4,
        notificationType: DeviceCategoryType.ENERGYMETER,
        root: true,
        path: '/energyDashboard',
    },

    {
        key: 'menu.weather_meter.dashboard',
        icon: <DesktopOutlined />,
        i18n: 'menu.weather_meter',
        type: ['WEATHER'],
        role: ['ROLE_CUSTOMER_SPECTATOR'],
        order: 3,
        notificationType: DeviceCategoryType.WEATHER,
        root: true,
        path: '/weatherDashboard',
    },
    {
        key: 'menu.weather_meter.sub',
        icon: <CloudOutlined />,
        i18n: 'menu.environment',
        type: ['WEATHER'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 4,
        notificationType: DeviceCategoryType.WEATHER,
        //path: '/weatherDashboard',
        root: true,
        submenu: [
            {
                key: 'menu.environment.outside',
                path: '/weatherDashboard',
                icon: <CloudDownloadOutlined />,
                i18n: 'menu.environment.outside',
                notificationType: DeviceCategoryType.WEATHER + '_OUTSIDE',
                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                queryId: EnvironmentType.OUTSIDE,
            },
            {
                key: 'menu.environment.inside',
                path: '/weatherDashboard',
                icon: <CloudUploadOutlined />,
                i18n: 'menu.environment.inside',
                notificationType: DeviceCategoryType.WEATHER + '_INSIDE',
                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                queryId: EnvironmentType.INSIDE,
            },
            {
                key: 'menu.weather_meter',
                path: '/weatherMeter',
                icon: <DesktopOutlined />,
                i18n: 'menu.device',
                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
            },
            {
                key: 'menu.weather_meter.group',
                path: '/weatherMetersGroup',
                icon: <GroupOutlined />,
                i18n: 'menu.weather_meter.group',
                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
            },
            {
                key: 'menu.weather_alarm_settings',
                path: '/alarmSettings',
                icon: <AlertOutlined />,
                i18n: 'menu.weather_alarm_settings',
                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
            },
        ],
    },
    {
        key: 'menu.industrial.sensors',
        icon: <AppstoreOutlined />,
        i18n: 'menu.industrial.sensors',
        type: ['WATERMETER', 'ENERGYMETER', 'LIGHTS'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 5,
        notificationType: 'INDUSTRIAL_SENSORS',
        root: true,
        submenu: [
            {
                key: 'menu.watermeter',
                icon: <DashboardOutlined />,
                i18n: 'menu.watermeter',
                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                type: ['WATERMETER'],
                order: 3,
                notificationType: DeviceCategoryType.WATERMETER,
                path: '/waterMeterDashboard',
                submenu: [
                    {
                        key: 'menu.watermeter.dmazone',
                        icon: <GroupOutlined />,
                        i18n: 'menu.dmazone',
                        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                        submenu: [
                            {
                                key: 'menu.watermeter.devicegroup',
                                path: '/device-groups',
                                icon: <GroupOutlined />,
                                i18n: 'menu.devicegroup',
                                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                            },
                            {
                                key: 'menu.watermeter.dma',
                                path: '/dma',
                                icon: <BarChartOutlined />,
                                i18n: 'menu.dma',
                                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                            },
                        ],
                    },
                    {
                        key: 'menu.watermeter.device',
                        path: '/devices',
                        icon: <DesktopOutlined />,
                        i18n: 'menu.device',
                        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                    },
                    {
                        key: 'menu.warehouse',
                        path: '/warehouse',
                        icon: <StockOutlined />,
                        i18n: 'menu.warehouse',
                        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                    },
                    {
                        key: 'menu.tasks',
                        path: '/tasks',
                        icon: <FileSearchOutlined />,
                        i18n: 'menu.tasks',
                        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                    },
                    {
                        key: 'menu.applications',
                        path: '/applications',
                        icon: <MobileOutlined />,
                        i18n: 'menu.applications',
                        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                    },
                ],
            },
            {
                key: 'menu.energy_meter.sub',
                icon: <DesktopOutlined />,
                i18n: 'menu.energy_meter',
                type: ['ENERGYMETER'],
                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                order: 4,
                notificationType: DeviceCategoryType.ENERGYMETER,
                path: '/energyDashboard',
                submenu: [
                    {
                        key: 'menu.energy_meter',
                        path: '/energyMeter',
                        icon: <DesktopOutlined />,
                        i18n: 'menu.device',
                        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                    },
                    {
                        key: 'menu.energy_meter.group',
                        path: '/energyMetersGroup',
                        icon: <GroupOutlined />,
                        i18n: 'menu.energy_meter.group',
                        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                    },
                ],
            },
            {
                key: 'menu.lights.sub',
                icon: <DesktopOutlined />,
                i18n: 'menu.lights',
                type: ['LIGHTS'],
                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                order: 4,
                notificationType: DeviceCategoryType.LIGHTS,
                path: '/lightsDashboard',
                submenu: [
                    {
                        key: 'menu.lights.lights',
                        path: '/lights',
                        icon: <DesktopOutlined />,
                        i18n: 'menu.device',
                        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                    },
                    {
                        key: 'menu.lights.group',
                        path: '/lightsGroup',
                        icon: <GroupOutlined />,
                        i18n: 'menu.lights.group',
                        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
                    },
                ],
            },
        ],
    },
    {
        key: 'menu.gateways',
        path: '/gateways',
        icon: <DeploymentUnitOutlined />,
        i18n: 'menu.gateways',
        type: ['WATERMETER', 'LIGHTS', 'ENERGYMETER', 'SHARED', 'WEATHER'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'],
        order: 6,
    },
    {
        key: 'menu.customers',
        path: '/customers',
        icon: <TeamOutlined />,
        i18n: 'menu.customers',
        type: ['WATERMETER', 'LIGHTS', 'ENERGYMETER', 'SHARED', 'WEATHER'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'],
        order: 7,
    },
    {
        key: 'menu.users',
        path: '/users',
        icon: <IdcardOutlined />,
        i18n: 'menu.users',
        type: ['WATERMETER', 'LIGHTS', 'ENERGYMETER', 'SHARED', 'WEATHER'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 8,
    },
    {
        key: 'menu.warehouse',
        path: '/warehouse',
        icon: <StockOutlined />,
        i18n: 'menu.warehouse',
        type: ['WATERMETER_ONLY'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 9,
    },
    {
        key: 'menu.tasks',
        path: '/tasks',
        icon: <FileSearchOutlined />,
        i18n: 'menu.tasks',
        type: ['WATERMETER_ONLY'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 10,
    },
    {
        key: 'menu.applications',
        path: '/applications',
        icon: <MobileOutlined />,
        i18n: 'menu.applications',
        type: ['WATERMETER_ONLY'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 11,
    },
    {
        key: 'menu.consumers',
        path: '/consumers',
        icon: <ShoppingCartOutlined />,
        i18n: 'menu.consumers',
        type: ['WATERMETER_ONLY'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN', 'ROLE_CUSTOMER'],
        order: 12,
    },
    {
        key: 'menu.codebooks',
        icon: <FileSearchOutlined />,
        i18n: 'menu.codebooks',
        type: ['WATERMETER', 'LIGHTS', 'SHARED', 'ENERGYMETER', 'WEATHER'],
        role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'],
        order: 13,
        submenu: [
            {
                key: 'menu.country',
                path: '/countries',
                icon: <GlobalOutlined />,
                i18n: 'menu.country',
                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'],
            },
            {
                key: 'menu.deviceType',
                path: '/deviceTypes',
                icon: <ClusterOutlined />,
                i18n: 'menu.deviceType',
                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'],
            },
            {
                key: 'menu.deviceModel',
                path: '/device-models',
                icon: <SubnodeOutlined />,
                i18n: 'menu.deviceModel',
                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'],
            },
            {
                key: 'menu.applicationSettings',
                path: '/applicationSettings',
                icon: <SettingOutlined />,
                i18n: 'menu.applicationSettings',
                role: ['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'],
            },
        ],
    },
];

const LayoutMain = () => {
    const [_menu, setMenu] = useState(
        menu.filter((m: any) => {
            return m.type === 'ALL';
        })
    );
    const history = useHistory();
    const location = useLocation();

    function initMenu() {
        let categoryType = getUserCategoryType();
        let userMenu: any = [];

        if (categoryType && categoryType.length > 0 && categoryType.length != 1) {
            userMenu = menu.filter((m: any) => {
                return categoryType.some((c: any) => {
                    return m.type.includes(c);
                });
            });
        } else if (categoryType && categoryType.length == 1) {
            let menuAll = menu.filter((m: any) => {
                return m.type.includes('SHARED');
            });
            let menuCategory = menu.filter((m: any) => {
                return m.type.includes(categoryType[0] + '_ONLY');
            });
            userMenu = menuAll.concat(menuCategory);
        } else
            userMenu = menu.filter((m: any) => {
                return m.type === 'SHARED';
            });
        if (
            NySession.hasAnyRole(['ROLE_CUSTOMER']) &&
            !userMenu.some((item: any) => item['key'] == 'menu.importExport')
        ) {
            NyRequestResolver.requestGet(CONSTANTS_REQ.USER.HAS_IMPORT_EXPORT_MENU).then((response: any) => {
                if (response.status === RESPONSE.OK) {
                    const newMenu = [
                        ...userMenu,
                        {
                            key: 'menu.importExport',
                            path: '/import-export',
                            icon: <SwapOutlined />,
                            i18n: 'menu.importExport',
                            role: ['ROLE_CUSTOMER'],
                            type: ['SHARED'],
                            order: 13,
                        },
                    ];
                    userMenu = newMenu;
                }
            });
        }
        return userMenu.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));
    }

    useEffect(() => {
        setMenu(initMenu());
    }, []);

    function generateRoutes(routes: any) {
        let retValue = [];
        let route;
        for (route of routes) {
            if (NySession.hasAnyRole(route.role)) {
                retValue.push(
                    <Route key={route.path} path={route.path} exact={route.exact} component={route.component} />
                );
            }
        }
        return retValue;
    }

    function headerSelect(value: any) {
        if (value.key === 'logout') {
            NySession.logoutUser();
            sessionStorage.removeItem('IoTSettings');
            if (NyUtils.load(APPLICATION_KEY + '-WIDGETS')) {
                localStorage.removeItem(APPLICATION_KEY + '-WIDGETS');
            }
            if (NyUtils.load(APPLICATION_KEY + '-LAYOUT')) {
                localStorage.removeItem(APPLICATION_KEY + '-LAYOUT');
            }
            if (NyUtils.load(APPLICATION_KEY + '-WATERMETER_WIDGET')) {
                localStorage.removeItem(APPLICATION_KEY + '-WATERMETER_WIDGET');
            }
            if (NyUtils.load(APPLICATION_KEY + '-WATERMETER_LAYOUT')) {
                localStorage.removeItem(APPLICATION_KEY + '-WATERMETER_LAYOUT');
            }
            if (NyUtils.load(APPLICATION_KEY + '-LIGHTS_WIDGET')) {
                localStorage.removeItem(APPLICATION_KEY + '-LIGHTS_WIDGET');
            }
            if (NyUtils.load(APPLICATION_KEY + '-LIGHTS_LAYOUT')) {
                localStorage.removeItem(APPLICATION_KEY + '-LIGHTS_LAYOUT');
            }
            if (NyUtils.load(APPLICATION_KEY + '-DASHBOARD_LOCK')) {
                localStorage.removeItem(APPLICATION_KEY + '-DASHBOARD_LOCK');
            }
            if (NyUtils.load(APPLICATION_KEY + '-CATEGORY_TYPE')) {
                localStorage.removeItem(APPLICATION_KEY + '-CATEGORY_TYPE');
            }
            if (NyUtils.load(APPLICATION_KEY + '-ALL_LAYOUT')) {
                localStorage.removeItem(APPLICATION_KEY + '-ALL_LAYOUT');
            }
            if (NyUtils.load(APPLICATION_KEY + '-ALL_WIDGET')) {
                localStorage.removeItem(APPLICATION_KEY + '-ALL_WIDGET');
            }
            history.push('/login');
        }
    }

    const Right = () => {
        return (
            <Menu style={{ display: 'flex', justifyContent: 'end' }} mode="horizontal" onSelect={headerSelect}>
                <Menu.Item key="version">
                    {geti18nText('login.build')} <p className="version">{NySession.getAppValue('VERSION')}</p>
                </Menu.Item>
                <SubMenu
                    className="ant-menu-item"
                    title={
                        <span className="submenu-title-wrapper">
                            <UserOutlined />
                            {NySession.getUser().fullName}
                        </span>
                    }
                >
                    <Menu.Item key="logout">
                        <LogoutOutlined />
                        {geti18nText('navbar.user.logout')}
                    </Menu.Item>
                </SubMenu>
                {NySession.getAppValue('I18N') != null && (
                    <Menu.Item key="lang">
                        <NyLanguageSelector
                            languages={NySession.getAppValue('I18N').language.available}
                            selectedLanguage={NyUtils.getSelectedLanguage()}
                            onSelect={(lang: object) => {
                                NyUtils.saveDefaults(lang);
                            }}
                            reloadPage={true}
                        />
                    </Menu.Item>
                )}
            </Menu>
        );
    };

    if (!NySession.isUserAuthenticated()) {
        return <Redirect to="/login" />;
    } else {
        return (
            <Layout>
                <NyLayoutHeader headerClassName="ny-header" headerContent={Right()} />
                <Layout>
                    <LayoutMenu
                        menuItems={_menu}
                        menuHistory={history}
                        menuLocation={location}
                        menuLogo={logo}
                        menuLogo1={logoMini}
                        menuTheme="dark"
                        //menuSiderBackgroundColor="#001529"
                        siderClassName="ny-sider"
                        menuClassName="ny-menu thin-scrollbar"
                        menuWidth={250}
                    />
                    <Layout className="main-layout">
                        <Content className="main-content thin-scrollbar">
                            <Switch>
                                {generateRoutes(mainRoutes)}
                                <Route
                                    path="*"
                                    render={(props) => (
                                        <Result
                                            status="404"
                                            title="404"
                                            subTitle={geti18nText('app.default.404')}
                                            extra={
                                                <Button type="primary">{geti18nText('app.default.404.back')}</Button>
                                            }
                                        />
                                    )}
                                />
                            </Switch>
                        </Content>
                    </Layout>
                </Layout>
            </Layout>
        );
    }
};

export default LayoutMain;
