import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IDeviceDetailsState {
    record: { [index: string]: any };
    visible: boolean;
    chartRecord?: { [index: string]: any };
    chartVisible?: boolean;
    chartParmKey? : string
}

const initialState: IDeviceDetailsState = {
    record: {},
    visible: false,
    chartRecord: {},
    chartVisible: false,
    chartParmKey: undefined
};

const deviceWeatherStateDetails = createSlice({
    name: 'deviceWeatherStateDetails',
    initialState,
    reducers: {
        deviceWeatherStateDetailsInit(state) {
            state.record = {};
            state.visible = false;
        },
        deviceWeatherStateDetailsOpen(state, action: PayloadAction<IDeviceDetailsState>) {
            const { record, visible } = action.payload;
            state.record = record;
            state.visible = visible;
        },
        deviceWeatherStateDetailsChartInit(state) {
            state.chartRecord = {};
            state.chartVisible = false;
            state.chartParmKey = undefined
        },
        deviceWeatherStateDetailsChartOpen(state, action) {
            const { chartRecord, chartVisible, chartParmKey } = action.payload;
            state.chartRecord = chartRecord;
            state.chartVisible = chartVisible;
            state.chartParmKey = chartParmKey
        },
    },
});

export const { deviceWeatherStateDetailsInit, deviceWeatherStateDetailsOpen, deviceWeatherStateDetailsChartInit,deviceWeatherStateDetailsChartOpen } = deviceWeatherStateDetails.actions;

export default deviceWeatherStateDetails.reducer;
