import React from 'react';
import { Typography } from 'antd';

import { geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';
import { CONSTANTS_REQ, WindDirectionDescription } from '../../../utils/Constants';

const { Title } = Typography;

interface IChartWeatherStateDataConsumption {
    period?: any;
    title: string | null;
    deviceId: number | null;
    dateFrom?: any;
    dateTo?: any;
    params?: string[];
}

const ChartWeatherStateDataConsumption: React.FC<IChartWeatherStateDataConsumption> = ({
    period,
    title,
    deviceId,
    dateFrom,
    dateTo,
    params = [],
}) => {
    const csvCustomizationColumns: any = () => {
        return [
            {
                avgWindDirection: (value: any) => {
                    return value != undefined ? value + '°' : '0°';
                },
            },
            {
                avgWindSpeed: (value: any) => {
                    return value != undefined ? value + ' m/s' : '0 m/s';
                },
            },
            {
                temp: (value: any) => {
                    return value != undefined ? value + ' °C' : '0 °C';
                },
            },
            {
                humidity: (value: any) => {
                    return value != undefined ? value + ' %' : '0 %';
                },
            },
            {
                airPressure: (value: any) => {
                    return value != undefined ? value + ' HPa' : '0 HPa';
                },
            },
            {
                avgNoise: (value: any) => {
                    return value != undefined ? value + ' dB' : '0 dB';
                },
            },
            {
                pm_10: (value: any) => {
                    return value != undefined ? value + ' ug/m3' : '0 ug/m3';
                },
            },
            {
                pm_25: (value: any) => {
                    return value != undefined ? value + ' ug/m3' : '0 ug/m3';
                },
            },
            {
                ts: (value: any) => {
                    if (value) {
                        const ts: Date = new Date(value);
                        return ts.toLocaleString(NyUtils.getSelectedLocale());
                    }
                    return '';
                },
            },
        ];
    };

    const columns = [
        {
            title: geti18nText('chart.data.consumption.table.windDirection'),
            dataIndex: 'avgWindDirection',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return <div>{WindDirectionDescription(record.avgWindDirection, record.avgWindSpeed)}</div>;
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.windSpeed'),
            dataIndex: 'avgWindSpeed',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.avgWindSpeed ? NyUtils.formatNumber(record.avgWindSpeed, 2) : '0,0'}
                        &nbsp;m/s
                    </div>
                );
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.temp'),
            dataIndex: 'temp',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.temp ? NyUtils.formatNumber(record.temp, 2) : '0'}
                        &nbsp;°C
                    </div>
                );
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.humidity'),
            dataIndex: 'humidity',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.humidity ? NyUtils.formatNumber(record.humidity, 2) : '0'}
                        &nbsp;%
                    </div>
                );
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.airPressure'),
            dataIndex: 'airPressure',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.airPressure ? NyUtils.formatNumber(record.airPressure, 2) : '0'}
                        &nbsp;HPa
                    </div>
                );
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.noise'),
            dataIndex: 'avgNoise',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.avgNoise ? NyUtils.formatNumber(record.avgNoise, 2) : '0'}
                        &nbsp;dB
                    </div>
                );
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.pm_10'),
            dataIndex: 'pm_10',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.pm_10 ? NyUtils.formatNumber(record.pm_10, 2) : '0'}
                        &nbsp;ug/m3
                    </div>
                );
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.pm_25'),
            dataIndex: 'pm_25',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.pm_25 ? NyUtils.formatNumber(record.pm_25, 2) : '0'}
                        &nbsp;ug/m3
                    </div>
                );
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.solarRadiation'),
            dataIndex: 'solarRadiation',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.solarRadiation ? NyUtils.formatNumber(record.solarRadiation, 2) : '0'}
                        &nbsp;
                    </div>
                );
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.sunshine_hours'),
            dataIndex: 'sunshineHours',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.sunshineHours ? NyUtils.formatNumber(record.sunshineHours, 2) : '0'}
                        &nbsp;min
                    </div>
                );
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.lux'),
            dataIndex: 'lux',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.lux ? NyUtils.formatNumber(record.lux, 2) : '0'}
                        &nbsp;
                    </div>
                );
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.soil_temperature'),
            dataIndex: 'soilTemperature',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.soilTemperature ? NyUtils.formatNumber(record.soilTemperature, 2) : '0'}
                        &nbsp;°C
                    </div>
                );
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.soil_moisture'),
            dataIndex: 'soilMoisture',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.soilMoisture ? NyUtils.formatNumber(record.soilMoisture, 2) : '0'}
                        &nbsp;%
                    </div>
                );
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.rainfall'),
            dataIndex: 'rainfall',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.rainfall ? NyUtils.formatNumber(record.rainfall, 2) : '0'}
                        &nbsp;
                    </div>
                );
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.UVIntensity'),
            dataIndex: 'uv_intensity',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                return (
                    <div>
                        {record.uv_intensity ? NyUtils.formatNumber(record.uv_intensity, 2) : '0'}
                        &nbsp;
                    </div>
                );
            },
        },
        {
            title: geti18nText('chart.data.consumption.table.ts'),
            dataIndex: 'ts',
            sorter: (a: string, b: string) => {},
            render: (text: string, record: { [index: string]: any }) => {
                if (record.ts) {
                    const ts: Date = new Date(record.ts);
                    return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                }
            },
        },
    ];

    const columnFilter = () => {
        let cols = columns.filter((col: any) => col.dataIndex === 'ts' || params?.includes(col.dataIndex));
        console.log('params ', params);
        console.log('cols ', cols);
        return cols;
    };

    return (
        <div>
            <Title level={4}>{geti18nText('weatherStatePayloadHistory.table.title') + ' - ' + title}</Title>
            <NyDataTable
                url={CONSTANTS_REQ.DEVICE.WEATHER_CONSUMPTION_DATA}
                hideButtons={true}
                addedData={{ ...period, deviceId: deviceId, timestampFrom: dateFrom, timestampTo: dateTo }}
                scroll={{ x: 800 }}
                readonly={true}
                fetchWhenChange={dateFrom}
                columns={columnFilter()}
                exportCSV={true}
                CSVFileName={geti18nText('weatherStatePayloadHistory.table.title') + ' - ' + title}
                colCSVCustomization={csvCustomizationColumns()}
            />
        </div>
    );
};

export default ChartWeatherStateDataConsumption;
