import React, { useState, useEffect } from 'react';
import { Modal, Row, Col, Tabs, Form, DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { geti18nText, NyRequestResolver, NySearchField, NyUtils, RESPONSE } from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import DeviceWeatherStateStatisticDetail from './DeviceWeatherStateStatisticDetail';
import {
    deviceWeatherStateDetailsChartInit,
    deviceWeatherStateDetailsInit,
} from '../../../slices/deviceWeatherStateDetailsReducer';
import moment from 'moment';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import WeatherChart from './charts/WeatherChart';
import WeatherRainfallBarChart from './charts/WatherRainfallBarChart';
import WeatherPolarChart from './charts/WeatherPolarChart';

type IDataResponse = {
    converted?: string;
    barValue?: number;
};

const DeviceWeatherChartDetail = (props: any) => {
    const { RangePicker } = DatePicker;
    const dispatch = useDispatch();
    const { chartRecord, chartVisible, chartParmKey } = useSelector(
        (state: RootState) => state.deviceWeatherStateDetails
    );
    const [modal, setModal] = useState(props.modal != undefined ? props.modal : true);
    const [modalTitle, setModalTitle] = useState(geti18nText('weatherStateDetails.modal.title'));
    const [period, setPeriod] = useState<any>({ id: 'last-24h', text: geti18nText('report.time_range.last24h') });
    const [spanPeriod, setSpanPeriod] = useState<any>({ dateFrom: null, dateTo: null });
    const [tempData, setTempData] = useState<any>(null);

    const [loading, setLoading] = useState(false);
    const [chartKey, setChartKey] = useState<any>(props.chartParmKey != undefined ? props.chartParmKey : undefined);
    const [selectedDevice, setSelectedDevice] = useState<any>(
        props.selectedDevice != undefined ? props.selectedDevice.id : undefined
    );
    const periods = [
        { id: 'last-24h', text: geti18nText('report.time_range.last24h') },
        { id: 'current-week', text: geti18nText('report.time_range.current_week') },
        { id: 'last-week', text: geti18nText('report.time_range.last_week') },
        { id: 'current-month', text: geti18nText('report.time_range.current_month') },
        { id: 'current-year', text: geti18nText('report.time_range.current_year') },
        { id: 'span', text: geti18nText('report.time_range.span') },
    ];
    const hideModal = () => {
        dispatch(deviceWeatherStateDetailsChartInit());
        setTempData(undefined);
    };

    useEffect(() => {
        if (chartRecord?.id != undefined) {
            setSelectedDevice(chartRecord.id);
        }
    }, [chartRecord]);

    useEffect(() => {
        if (chartParmKey != undefined) {
            setChartKey(chartParmKey);
        }
    }, [chartParmKey]);

    useEffect(() => {
        if (chartKey != null && selectedDevice != undefined) {
            const dateFrom = spanPeriod != null && spanPeriod.dateFrom ? spanPeriod.dateFrom : null;
            const dateTo = spanPeriod != null && spanPeriod.dateTo ? spanPeriod.dateTo : null;
            fetchData(selectedDevice, period.id, chartKey?.toLocaleLowerCase(), setTempData, dateFrom, dateTo);
        }
    }, [chartKey, period, spanPeriod]);

    function disabledDate(current: any) {
        const rangeStart = moment().subtract(1, 'years');
        const rangeEnd = moment().endOf('day');
        return !(rangeStart.isSameOrBefore(current) && rangeEnd.isAfter(current));
    }
    function onRangeChange(dates: any) {
        if (dates != null) {
            const timestampFrom = dates[0] ? moment(dates[0]).valueOf() : null;
            const timestampTo = dates[1] ? moment(dates[1]).valueOf() : null;
            setSpanPeriod({ dateFrom: timestampFrom, dateTo: timestampTo });
        }
    }
    function periodOnChange(val: any) {
        console.log(val);
        setPeriod(val);
    }

    const fetchData = (deviceId: any, period: any, parName: any, setValue: any, dateFrom: any, dateTo: any) => {
        let data: any = {
            period: period,
            lang: NyUtils.getSelectedLanguage(),
            deviceId: deviceId,
            parName: parName,
        };

        if (dateFrom != null) {
            data['timestampFrom'] = dateFrom;
        }

        if (dateTo != null) {
            data['timestampTo'] = dateTo;
        }
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_WEATHER_CONSUMPTION, data).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                if (parName != 'wind') {
                    let data: IDataResponse[] = response.data;
                    data = data.filter((x) => x.barValue != null);
                    setValue(data);
                } else {
                    setValue(response.data);
                }
            }
        });
    };

    const settings: any = [
        {
            key: 'temp',
            settings: {
                title: geti18nText('weatherStateDetails.graph.title.temp'),
                yLabel: geti18nText('weatherStateDetails.graph.y.value.temp'),
                color: '#FF5733',
            },
            params: ['temp'],
        },
        {
            key: 'humidity',
            settings: {
                title: geti18nText('weatherStateDetails.graph.title.humidity'),
                yLabel: geti18nText('weatherStateDetails.graph.y.value.humidity'),
                color: '#FF0000',
            },
            params: ['humidity'],
        },
        {
            key: 'air_pressure',
            settings: {
                title: geti18nText('weatherStateDetails.graph.title.airPressure'),
                yLabel: geti18nText('weatherStateDetails.graph.y.value.airPressure'),
                color: '#C70039',
            },
            params: ['airPressure'],
        },
        {
            key: 'solar_radiation',
            settings: {
                title: geti18nText('weatherStateDetails.graph.title.solarRadiation'),
                yLabel: geti18nText('weatherStateDetails.graph.y.value.solarRadiation'),
                color: '#7393B3',
            },
            params: ['solarRadiation'],
        },
        {
            key: 'pm_10',
            settings: {
                title: geti18nText('weatherStateDetails.graph.title.pm_10'),
                yLabel: geti18nText('weatherStateDetails.graph.y.value.pm_10'),
                color: '#00A36C',
            },
            params: ['pm_10'],
        },
        {
            key: 'pm_25',
            settings: {
                title: geti18nText('weatherStateDetails.graph.title.pm_25'),
                yLabel: geti18nText('weatherStateDetails.graph.y.value.pm_25'),
                color: '#00A36C',
            },
            params: ['pm_25'],
        },
        {
            key: 'avg_noise',
            settings: {
                title: geti18nText('weatherStateDetails.graph.title.noise'),
                yLabel: geti18nText('weatherStateDetails.graph.y.value.noise'),
                color: '#003666',
            },
            params: ['avgNoise'],
        },
        {
            key: 'uv_intensity',
            settings: {
                title: geti18nText('weatherStateDetails.graph.title.UVIntensity'),
                yLabel: geti18nText('weatherStateDetails.graph.y.value.UVIntensity'),
                color: '#8e43e7',
            },
            params: ['uv_intensity'],
        },
        {
            key: 'solar_radiation',
            settings: {
                title: geti18nText('weatherStateDetails.graph.title.solarRadiation'),
                yLabel: geti18nText('weatherStateDetails.graph.y.value.solarRadiation'),
                color: '#7393B3',
            },
            params: ['solar_radiation'],
        },
        {
            key: 'sunshine_hours',
            settings: {
                title: geti18nText('weatherStateDetails.graph.title.sunshine_hours'),
                yLabel: geti18nText('weatherStateDetails.graph.y.value.sunshine_hours'),
                color: '#7393B3',
            },
            params: ['sunshineHours'],
        },
        {
            key: 'soil_temperature',
            settings: {
                title: geti18nText('weatherStateDetails.graph.title.soil_temperature'),
                yLabel: geti18nText('weatherStateDetails.graph.y.value.soil_temperature'),
                color: '#FF5733',
            },
            params: ['soilTemperature'],
        },
        {
            key: 'soil_moisture',
            settings: {
                title: geti18nText('weatherStateDetails.graph.title.soil_moisture'),
                yLabel: geti18nText('weatherStateDetails.graph.y.value.soil_moisture'),
                color: '#FF0000',
            },
            params: ['soilMoisture'],
        },
        {
            key: 'lux',
            settings: {
                title: geti18nText('weatherStateDetails.graph.title.lux'),
                yLabel: geti18nText('weatherStateDetails.graph.y.value.lux'),
                color: '#7393B3',
            },
            params: ['lux'],
        },
    ];

    function getSettings(key: string) {
        const index = settings.findIndex((x: any) => x.key == key);
        if (index > -1) {
            return settings[index].settings;
        }

        return undefined;
    }

    function getParam(key: string) {
        const index = settings.findIndex((x: any) => x.key == key);
        if (index > -1) {
            return settings[index].params;
        }
        return undefined;
    }

    const content = () => {
        return (
            <>
                <Row>
                    <Col span={12}>
                        <Form.Item label={geti18nText('report.time_range.select_period')}>
                            <NySearchField
                                value={period}
                                onChange={periodOnChange}
                                searchBy={'name'}
                                style={{ width: '90%' }}
                                options={periods}
                            />
                        </Form.Item>
                    </Col>
                    {period != undefined && period.id == 'span' && (
                        <Col>
                            <Row gutter={24} style={{ marginBottom: '10px' }}>
                                <Col span={24}>
                                    <RangePicker
                                        disabledDate={disabledDate}
                                        showTime
                                        format="DD.MM.YYYY HH:mm:ss"
                                        onChange={onRangeChange}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    )}
                </Row>
                <Row>
                    {' '}
                    {chartKey != undefined && (
                        <Col span={24}>
                            {chartKey == 'rainfall' ? (
                                <WeatherRainfallBarChart
                                    period={period.id}
                                    params={['rainfall']}
                                    deviceId={selectedDevice}
                                    data={tempData}
                                    loading={loading}
                                    title={period.text}
                                ></WeatherRainfallBarChart>
                            ) : chartKey == 'wind' ? (
                                <WeatherPolarChart
                                    loading={loading}
                                    data={tempData}
                                    title={period.text}
                                    deviceId={selectedDevice}
                                    period={period.id}
                                    showYLabel={true}
                                    params={['avgWindDirection', 'avgWindSpeed']}
                                    settings={{
                                        title: geti18nText('weatherStateDetails.graph.title.wind'),
                                        yLabel: geti18nText(''),
                                        color: '#FF5733',
                                    }}
                                />
                            ) : (
                                <WeatherChart
                                    loading={loading}
                                    data={tempData}
                                    title={period.text}
                                    deviceId={selectedDevice}
                                    period={period.id}
                                    showYLabel={true}
                                    params={chartKey != undefined ? getParam(chartKey) : []}
                                    settings={chartKey != undefined ? getSettings(chartKey) : []}
                                />
                            )}
                        </Col>
                    )}
                </Row>
            </>
        );
    };

    return modal == true ? (
        <Modal
            zIndex={1009}
            title={modalTitle}
            destroyOnClose={true}
            visible={chartVisible}
            onOk={hideModal}
            onCancel={hideModal}
            width="90%"
            footer={null}
        >
            {content()}
        </Modal>
    ) : (
        content()
    );
};

export default DeviceWeatherChartDetail;
