import React, { useState } from 'react';
import { Button, Popconfirm, Row, Tag, Tooltip } from 'antd';
import { CONSTANTS_REQ, DeviceCategoryType } from '../../utils/Constants';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../rootReducer';
import { GetEnumNameForValue } from '../../utils/Enums';
import { FormOutlined } from '@ant-design/icons';
import { alarmDescriptionOpen, refreshDeviceAlarmList } from '../../slices/alarmDescriptionSlice';
import {
    getColumnSearch,
    getColumnSearchOption,
    geti18nText,
    NyDataTable,
    NyRequestResolver,
    NyUtils,
    RESPONSE,
} from '@nybble/nyreact';
import { getUnreadAlarmFilterType } from '../../utils/Utils';

const Alarms = () => {
    const { record } = useSelector((state: RootState) => state.deviceDetails);
    const { deviceAlarmListRefresh } = useSelector((state: RootState) => state.alarmDescriptionSlice);
    const dispatch = useDispatch();
    const [showAlarmBtn, setShowAlarmBtn] = useState<boolean>(false);

    const setDefaultFilterValue = () => {
        return [{ field: 'deviceId', condition: 'equals', value: record.id }];
    };

    const editDescription = (deviceId: string, data?: { [index: string]: any }) => {
        dispatch(alarmDescriptionOpen({ deviceId: deviceId, data: data, visible: true, deviceAlarmListRefresh: 0 }));
    };
    const onUnreadClick = (record: any) => {
        NyRequestResolver.requestPut(CONSTANTS_REQ.ALARM.READ + '/' + record.id, undefined, {
            id: record.id,
            category: record.deviceCategory,
        }).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                dispatch(refreshDeviceAlarmList());
            }
        });
    };
    const onLoadData = (data: { [index: string]: any } | string, params: { [index: string]: any } | undefined) => {
        setShowAlarmBtn(data != undefined && data.length > 0 ? true : false);
        if (data != undefined && data.length > 0) {
            const items: any = data;
            const firstItem = items[0];
            if (firstItem.unread == false) {
                setShowAlarmBtn(false);
            }
        }
    };
    const onUnreadAllClick = () => {
        let item1: any = { deviceId: record.id, category: DeviceCategoryType.WATERMETER };
        NyRequestResolver.requestPost(CONSTANTS_REQ.ALARM.READ_ALL, undefined, item1).then((result: any) => {
            if (result && result.status === RESPONSE.OK) {
                dispatch(refreshDeviceAlarmList());
            }
        });
    };

    return (
        <div className="box">
            {showAlarmBtn == true && (
                <Row gutter={24} style={{ float: 'right' }}>
                    <Popconfirm
                        title={geti18nText('dashboard.widget.alarm.table.unread_all.btn.msg')}
                        onConfirm={() => {
                            onUnreadAllClick();
                        }}
                    >
                        <Button className="margin-right" danger style={{ right: '25px', top: '5px' }}>
                            {geti18nText('dashboard.widget.alarm.table.unread_all.btn')}
                        </Button>
                    </Popconfirm>
                </Row>
            )}

            <NyDataTable
                headerTitle={geti18nText('dashboard.widget.alarm.table.title')}
                url={CONSTANTS_REQ.DASHBOARD.WATERMETER_ALARMS}
                hideButtons={true}
                scroll={{ y: 175, x: 800 }}
                setDefaultFilterValue={setDefaultFilterValue}
                addedData={record.id}
                exportCSV={true}
                fetchWhenChange={deviceAlarmListRefresh}
                readonly={true}
                onDataLoaded={onLoadData}
                setDefaultSortOrder={{ order: 'unread', orderType: 'desc' }}
                columns={[
                    {
                        title: geti18nText('dashboard.widget.alarm.table.name'),
                        dataIndex: ['device', 'name'],
                        sorter: (a: string, b: string) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('dashboard.widget.alarm.table.serial'),
                        dataIndex: ['device', 'eui'],
                        sorter: (a: string, b: string) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('dashboard.widget.alarm.table.address'),
                        dataIndex: ['contact', 'address'],
                        sorter: (a: string, b: string) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('dashboard.widget.alarm.table.waterusage'),
                        dataIndex: ['deviceVolume', 'volume'],
                        align: 'right',
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.volume ? NyUtils.formatNumber(record.volume, 3) : '0,0'}
                                    &nbsp;m3
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('dashboard.widget.alarm.table.type'),
                        dataIndex: 'id',
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    <b style={{ color: 'red' }}>
                                        {geti18nText(
                                            'app.enum.ALARM_STATUS.' +
                                                GetEnumNameForValue('ALARM_STATUS', record.alarmStatus)
                                        )}
                                    </b>
                                </div>
                            );
                        },
                    },
                    {
                        title: geti18nText('dashboard.widget.alarm.table.lastactivity'),
                        dataIndex: ['deviceVolume', 'ts'],
                        sorter: (a: string, b: string) => {},
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.deviceVolume.ts) {
                                const ts: Date = new Date(record.deviceVolume.ts);
                                return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                            }
                        },
                    },
                    {
                        dataIndex: 'operation',
                        width: '10%',
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <React.Fragment>
                                    <Tooltip title={geti18nText('dashboard.widget.alarm.table.description.tooltip')}>
                                        {record.description ? (
                                            <FormOutlined
                                                style={{
                                                    fontSize: '18px',
                                                    marginRight: '10px',
                                                    color: '#40a9ff',
                                                }}
                                                onClick={() =>
                                                    editDescription(record.alarm_id, {
                                                        description: record.description,
                                                    })
                                                }
                                            />
                                        ) : (
                                            <FormOutlined
                                                style={{
                                                    fontSize: '18px',
                                                    marginRight: '10px',
                                                }}
                                                onClick={() =>
                                                    editDescription(record.alarm_id, {
                                                        description: record.description,
                                                    })
                                                }
                                            />
                                        )}
                                    </Tooltip>
                                </React.Fragment>
                            );
                        },
                    },
                    {
                        dataIndex: 'unread',
                        sorter: (a: string, b: string) => {},
                        width: '5%',
                        ...getColumnSearchOption(
                            getUnreadAlarmFilterType(),
                            'equals_bool',
                            setDefaultFilterValue()[0]['value']
                        ),
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.unread == true) {
                                return (
                                    <Popconfirm
                                        title={geti18nText('dashboard.widget.alarm.table.unread.btn.msg')}
                                        onConfirm={() => {
                                            onUnreadClick(record);
                                        }}
                                    >
                                        <Tag color="red">{geti18nText('dashboard.widget.alarm.table.unread.btn')}</Tag>
                                    </Popconfirm>
                                );
                            }
                        },
                    },
                ]}
            />
        </div>
    );
};

export default Alarms;
