import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EnvironmentType } from '../utils/Constants';

interface IDashboardState {
    mapRefresh: number;
    watermeterListRefresh: number;
    lightsListRefresh: number;
    alarmListRefresh: number;
    deviceStatusChanged: { [index: string]: string };
    lastDeviceStatusChanged: string | undefined;
    filterActive: boolean;
    filterInactive: boolean;
    filterLowBattery: boolean;
    filterDevice: number | undefined;
    filterAlarmStatus: number | undefined;
    filterDMA: number | undefined;
    filterSource: number | undefined;
    showWatermeters: boolean;
    showLights: boolean;
    showEnergymeters: boolean;
    showWeatherstate: boolean;
    lightsActive: boolean;
    energyMeterActive: boolean;
    weatherStateActive: boolean;
    weatherStateInactive: boolean;
    lightsAlarm: boolean;
    mapBounds: string | undefined;
    mapSelectedBounds:
        | { latNorthEast: number; lngNorthEast: number; latSouthWest: number; lngSouthWest: number }
        | undefined;
    mapPanTo: { lat: number; lng: number } | undefined;
    visibleGenerateTasks: boolean;
    weatherFavoritList:any;
    selectedIdToShowDetail:any;
    selectedDeviceGroupId:any;
    queryId: any
    searchRefresh: number
    selectedWeatherDataType: any
}

const initialState: IDashboardState = {
    mapRefresh: 0,
    watermeterListRefresh: 0,
    lightsListRefresh: 0,
    alarmListRefresh: 0,
    deviceStatusChanged: {},
    lastDeviceStatusChanged: undefined,
    filterActive: false,
    filterInactive: false,
    filterLowBattery: false,
    filterDevice: undefined,
    filterAlarmStatus: undefined,
    filterDMA: undefined,
    showWatermeters: true,
    showLights: true,
    showEnergymeters: true,
    showWeatherstate: true,
    filterSource: undefined,
    mapBounds: undefined,
    mapSelectedBounds: undefined,
    mapPanTo: undefined,
    visibleGenerateTasks: false,
    lightsActive: false,
    lightsAlarm: false,
    energyMeterActive: false,
    weatherStateActive: false,
    weatherStateInactive: false,
    weatherFavoritList: [],
    selectedIdToShowDetail: undefined,
    queryId :  EnvironmentType.OUTSIDE,
    selectedDeviceGroupId: undefined,
    selectedWeatherDataType: undefined,
    searchRefresh : 0
};

interface IDeviceStatusState {
    id: string;
    status: string;
}

interface IAlarmStatus {
    status: number | undefined;
    source: number | undefined;
}

interface IMapBoiunds {
    bounds: string;
}
interface IMapSelectedBounds {
    latNorthEast: number;
    lngNorthEast: number;
    latSouthWest: number;
    lngSouthWest: number;
}

interface IDevice {
    id: number | undefined;
}

interface IMapPan {
    lat: number;
    lng: number;
}

const dashboard = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        refreshMap(state) {
            state.mapRefresh++;
        },
        refreshWatermeterList(state) {
            state.watermeterListRefresh++;
        },
        refresLightsList(state) {
            state.lightsListRefresh++;
        },
        startRefresAlarmList(state) {
            state.alarmListRefresh = 1;
        },
        refreshAlarmList(state) {
            state.alarmListRefresh++;
        },
        stopRefresAlarmList(state) {
            state.alarmListRefresh = -1;
        },
        clearDeviceStatusChanged(state) {
            state.deviceStatusChanged = {};
            state.lastDeviceStatusChanged = undefined;
        },
        addNewDeviceStatusChanged(state, action: PayloadAction<IDeviceStatusState>) {
            state.deviceStatusChanged[action.payload.id] = action.payload.status;
            state.lastDeviceStatusChanged = action.payload.id;
        },
        filterActiveDevices(state) {
            state.filterActive = true;
            state.filterInactive = false;
            state.filterLowBattery = false;
            state.filterDevice = undefined;
            state.filterAlarmStatus = undefined;
            state.filterSource = undefined;
            state.showWatermeters = true;
        },
        filterActiveLights(state) {
            state.filterDevice = undefined;
            state.filterAlarmStatus = undefined;
            state.filterSource = undefined;
            state.lightsActive = true;
            state.showLights = true;
        },
        filterActiveWeatherState(state) {
            state.filterDevice = undefined;
            state.filterAlarmStatus = undefined;
            state.filterSource = undefined;
            state.weatherStateActive = true;
            state.weatherStateInactive = false;
            state.showWeatherstate = true;
        },
        filterInactiveWeatherState(state) {
            state.filterDevice = undefined;
            state.filterAlarmStatus = undefined;
            state.filterSource = undefined;
            state.weatherStateActive = false;
            state.weatherStateInactive = true;
            state.showWeatherstate = true;
        },
        filterAlarmLights(state) {
            state.filterDevice = undefined;
            state.filterAlarmStatus = undefined;
            state.filterSource = undefined;
            state.lightsAlarm = true;
            state.showLights = true;
        },
        filterActiveLightsReset(state) {
            state.lightsActive = false;
        },
        filterActiveWeatherStateReset(state) {
            state.weatherStateActive = false;
        },
        filterInactiveWeatherStateReset(state) {
            state.weatherStateInactive = false;
        },
        filterAlarmLightsReset(state) {
            state.lightsAlarm = false;
        },
        filterInactiveDevices(state) {
            state.filterActive = false;
            state.filterInactive = true;
            state.filterLowBattery = false;
            state.filterDevice = undefined;
            state.filterAlarmStatus = undefined;
            state.filterSource = undefined;
            state.showWatermeters = true;
        },
        filterLowBatteryDevices(state) {
            state.filterActive = false;
            state.filterInactive = false;
            state.filterLowBattery = true;
            state.filterDevice = undefined;
            state.filterAlarmStatus = undefined;
            state.filterSource = undefined;
            state.showWatermeters = true;
        },
        filterDeviceDevices(state, action: PayloadAction<IDevice>) {
            state.filterActive = false;
            state.filterInactive = false;
            state.filterLowBattery = false;
            state.filterDevice = action.payload.id;
            state.filterAlarmStatus = undefined;
            state.filterSource = undefined;
            state.showWatermeters = true;
            state.showLights = true;
            state.showEnergymeters = true;
            state.showWeatherstate = true
        },
        filterDeviceWithAlarmStatus(state, action: PayloadAction<IAlarmStatus>) {
            /*state.filterActive = false;
            state.filterInactive = false;
            state.filterLowBattery = false;*/
            state.filterAlarmStatus = action.payload.status;
            state.filterSource = action.payload.source;
            state.showWatermeters = true;
            state.showLights = false;
        },
        filterReset(state) {
            state.filterActive = false;
            state.filterInactive = false;
            state.filterLowBattery = false;
            state.filterDevice = undefined;
            state.filterAlarmStatus = undefined;
            state.filterDMA = undefined;
            state.filterSource = undefined;
            state.mapBounds = undefined;
            state.mapPanTo = undefined;
            state.mapSelectedBounds = undefined;
            state.showWatermeters = true;
            state.showLights = true;
            state.showWeatherstate = true;
            state.lightsActive = false;
            state.lightsAlarm = false;
            state.showEnergymeters = true;
            state.selectedDeviceGroupId = undefined;
            state.queryId = EnvironmentType.OUTSIDE
            state.selectedIdToShowDetail = undefined
            state.searchRefresh++
            state.selectedWeatherDataType = undefined
        },
        setMapBounds(state, action: PayloadAction<IMapBoiunds>) {
            state.mapBounds = action.payload.bounds;
        },
        setMapSelectedBounds(state, action: PayloadAction<IMapSelectedBounds>) {
            state.mapSelectedBounds = {
                latNorthEast: action.payload.latNorthEast,
                lngNorthEast: action.payload.lngNorthEast,
                latSouthWest: action.payload.latSouthWest,
                lngSouthWest: action.payload.lngSouthWest,
            };
        },
        setMapPanTo(state, action: PayloadAction<IMapPan>) {
            state.mapPanTo = { lat: action.payload.lat, lng: action.payload.lng };
        },
        hideGenerateTasks(state) {
            state.visibleGenerateTasks = false;
        },
        showGenerateTasks(state) {
            state.visibleGenerateTasks = true;
        },

        showWatermeterButton(state, action: PayloadAction<boolean>) {
            state.showWatermeters = action.payload;
        },
        showLightButton(state, action: PayloadAction<boolean>) {
            state.showLights = action.payload;
        },
        showEnergyButton(state, action: PayloadAction<boolean>) {
            state.showEnergymeters = action.payload;
        },
        showWeatherStateButton(state, action: PayloadAction<boolean>) {
            state.showWeatherstate = action.payload;
        },
        addToWeatherFavorit(state,action) {
            state.weatherFavoritList.push(action.payload)
        },
        setWeatherFavoritList(state,action) {
            state.weatherFavoritList=action.payload
        },
        removeFromWeatherList(state,action) {
            state.weatherFavoritList=state.weatherFavoritList.filter((item:any) => item.id !== action.payload.id);
        },
        setSelectedIdToShowDetails(state,action) {
            state.selectedIdToShowDetail=action.payload
        },
        setSelectedDeviceGroupId(state,action) {
            state.selectedDeviceGroupId=action.payload
        },
        setQueryId(state,action){
            state.queryId=action.payload
        },
        setSelectedWeatherDataType(state,action){
            state.selectedWeatherDataType=action.payload
        }


        
    },
});

export const {
    refreshMap,
    refreshWatermeterList,
    refresLightsList,
    refreshAlarmList,
    startRefresAlarmList,
    stopRefresAlarmList,
    clearDeviceStatusChanged,
    addNewDeviceStatusChanged,
    filterActiveDevices,
    filterInactiveDevices,
    filterLowBatteryDevices,
    filterDeviceDevices,
    filterDeviceWithAlarmStatus,
    filterReset,
    setMapBounds,
    setMapSelectedBounds,
    setMapPanTo,
    hideGenerateTasks,
    showGenerateTasks,
    showWatermeterButton,
    showLightButton,
    showWeatherStateButton,
    showEnergyButton,
    filterActiveLights,
    filterActiveLightsReset,
    filterAlarmLights,
    filterAlarmLightsReset,
    filterActiveWeatherStateReset,
    filterActiveWeatherState,
    filterInactiveWeatherState,
    filterInactiveWeatherStateReset,
    addToWeatherFavorit,
    setWeatherFavoritList,
    removeFromWeatherList,
    setSelectedIdToShowDetails,
    setQueryId,
    setSelectedDeviceGroupId,
    setSelectedWeatherDataType
} = dashboard.actions;

export default dashboard.reducer;
