import { CloudServerOutlined, DragOutlined, LockOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Badge, Button, Tooltip } from 'antd';
import L from 'leaflet';
import 'leaflet-area-select';
//import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useRef, useState } from 'react';
import { FeatureGroup, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import {
    filterReset,
    setMapBounds,
    setMapSelectedBounds,
    showEnergyButton,
    showLightButton,
    showWatermeterButton,
    showWeatherStateButton,
} from '../../slices/dashboardSlice';
import { deviceDetailsOpen } from '../../slices/deviceDetailsSlice';
import { deviceLightDetailsOpen } from '../../slices/deviceLightDetailsSlice';
import { CONSTANTS_REQ, DeviceCategoryType } from '../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../utils/Enums';
import { IWidget } from './index';
import 'leaflet-draw';
import FormatNumber from '../number-formatter';
import { deviceEnergyDetailsOpen } from '../../slices/deviceEnergyDetailsSlice';
import { deviceWeatherStateDetailsOpen } from '../../slices/deviceWeatherStateDetailsReducer';
import { useHistory } from 'react-router-dom';

const WidgetMap: React.FC<IWidget> = ({
    dataGrid,
    elementKey,
    showLightsOn,
    showEnergyOn,
    showWatermeterOn,
    showWeatherStateOn,
    refreshFilter,
    showOnlyCategory,
}) => {
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
    const [items, setItems] = useState<{ [index: string]: any }[]>([]);
    const [lightItems, setLightItems] = useState<{ [index: string]: any }[]>([]);
    const [energyItems, setEnergyItems] = useState<{ [index: string]: any }[]>([]);
    const [weatherStateItems, setWeatherStateItems] = useState<{ [index: string]: any }[]>([]);
    const [statusChanged, setStatusChanged] = useState<{ [index: string]: any }[]>([]);
    const { alarmCount } = useSelector((state: RootState) => state.alarmDescriptionSlice);
    const history = useHistory();
    useEffect(() => {
        console.log('ALARM COUNT ', alarmCount);
    }, [alarmCount]);

    const mapRef = useRef<L.Map>();
    const mapFeatureRef = useRef<any>();
    const [layerId, setLayerId] = useState<any>(null);
    const dispatch = useDispatch();
    const {
        mapRefresh,
        mapBounds,
        mapPanTo,
        showWatermeters,
        showLights,
        showEnergymeters,
        showWeatherstate,
    } = useSelector((state: RootState) => state.dashboard);
    const mapOptions = {
        ref: mapRef,
        className: 'markercluster-map',
        center: [44.3057, 16.3366],
        zoom: 6,
        maxZoom: 18,
        gestureHandling: true,
        style: {
            height: dataGrid.h && typeof dataGrid.h == 'number' ? dataGrid.h * 40 - 20 + 'px' : '88vh',
        },
    };

    useEffect(() => {
        if (refreshFilter) {
            clearFilter();
        }
    }, [refreshFilter]);

    useEffect(() => {
        L.drawLocal.draw.toolbar.buttons.rectangle = geti18nText('map.draw.select.btn.rectangle');
        L.drawLocal.draw.handlers.rectangle.tooltip.start = geti18nText('map.draw.select.rectangle.tooltip.start');
        L.drawLocal.edit.toolbar.buttons.edit = geti18nText('map.draw.select.rectangle.edit.toolbar.buttons.edit');
        L.drawLocal.edit.toolbar.buttons.editDisabled = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.buttons.editDisabled'
        );
        L.drawLocal.edit.toolbar.buttons.remove = geti18nText('map.draw.select.rectangle.edit.toolbar.buttons.remove');
        L.drawLocal.edit.toolbar.buttons.removeDisabled = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.buttons.removeDisabled'
        );
        L.drawLocal.edit.toolbar.actions.save.title = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.actions.save.title'
        );
        L.drawLocal.edit.toolbar.actions.save.text = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.actions.save.text'
        );
        L.drawLocal.edit.toolbar.actions.cancel.title = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.actions.cancel.title'
        );
        L.drawLocal.edit.toolbar.actions.cancel.text = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.actions.cancel.text'
        );
        L.drawLocal.edit.toolbar.actions.clearAll.title = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.actions.clearAll.title'
        );
        L.drawLocal.edit.toolbar.actions.clearAll.text = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.actions.clearAll.text'
        );
        L.drawLocal.edit.handlers.edit.tooltip.text = geti18nText(
            'map.draw.select.rectangle.edit.handlers.edit.tooltip.text'
        );
        L.drawLocal.edit.handlers.edit.tooltip.subtext = geti18nText(
            'map.draw.select.rectangle.edit.handlers.edit.tooltip.subtext'
        );
        L.drawLocal.edit.handlers.remove.tooltip.text = geti18nText(
            'map.draw.select.rectangle.edit.handlers.remove.tooltip.text'
        );
        L.drawLocal.draw.toolbar.actions.title = geti18nText('map.draw.select.rectangle.draw.toolbar.actions.title');
        L.drawLocal.draw.toolbar.actions.text = geti18nText('map.draw.select.rectangle.draw.toolbar.actions.text');
        L.drawLocal.draw.toolbar.finish.title = geti18nText('map.draw.select.rectangle.draw.toolbar.finish.title');
        L.drawLocal.draw.toolbar.finish.text = geti18nText('map.draw.select.rectangle.draw.toolbar.finish.text');
        if (!showOnlyCategory) {
            fetchPins();
            fetchLightPins();
            fetchEnergyPins();
            fetchWeatheStatePins();
        } else {
            if (showWatermeterOn) fetchPins();
            if (showLightsOn) fetchLightPins();
            if (showEnergyOn) fetchEnergyPins();
            if (showWeatherStateOn) fetchWeatheStatePins();
        }
        // fetchLightPins();
    }, [mapRefresh]);

    useEffect(() => {
        if (mapRef.current && mapBounds != undefined) {
            let west, south, east, north;
            [west, south, east, north] = mapBounds.split(',').map(parseFloat);
            var bounds = new L.LatLngBounds(new L.LatLng(south, west), new L.LatLng(north, east));
            mapRef.current.flyToBounds(bounds);
        }
    }, [mapBounds]);

    useEffect(() => {
        if (mapRef && mapRef.current !== null && mapPanTo != undefined) {
            mapRef.current?.flyTo(mapPanTo, 18);
        }
    }, [mapPanTo]);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchPinStatusChange();
        }, 5 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const fetchPinStatusChange = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DASHBOARD.STATUSREFRESH).then((result) => {
            if (result && result.status == RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    setStatusChanged(result.data);
                }
            }
        });
    };

    function fetchPins() {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DASHBOARD.MAPPINS).then((result) => {
            if (result && result.status == RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    setItems(result.data);
                }

                var bounds: L.LatLngBounds | undefined = undefined;

                if (Array.isArray(result.data)) {
                    for (let pinData of result.data) {
                        if (bounds === undefined)
                            bounds = L.latLngBounds([pinData.lat, pinData.lng], [pinData.lat, pinData.lng]);
                        else bounds.extend([pinData.lat, pinData.lng]);
                    }
                }
                if (mapRef && mapRef.current && bounds != undefined) {
                    dispatch(setMapBounds({ bounds: bounds.toBBoxString() }));
                    mapRef.current?.fitBounds(bounds);
                }
            }
        });
    }

    function fetchLightPins() {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DASHBOARD.LIGHT_MAPPINS).then((result) => {
            if (result && result.status == RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    setLightItems(result.data);
                }

                var bounds: L.LatLngBounds | undefined = !showOnlyCategory ? mapRef.current?.getBounds() : undefined;

                if (Array.isArray(result.data)) {
                    for (let pinData of result.data) {
                        if (bounds === undefined)
                            bounds = L.latLngBounds([pinData.lat, pinData.lng], [pinData.lat, pinData.lng]);
                        else bounds.extend([pinData.lat, pinData.lng]);
                    }
                }
                if (mapRef && mapRef.current && bounds != undefined) {
                    dispatch(setMapBounds({ bounds: bounds.toBBoxString() }));
                    mapRef.current?.fitBounds(bounds);
                }
            }
        });
    }

    function fetchEnergyPins() {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DASHBOARD.ENERGY_MAPPINS).then((result) => {
            if (result && result.status == RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    setEnergyItems(result.data);
                }

                var bounds: L.LatLngBounds | undefined = !showOnlyCategory ? mapRef.current?.getBounds() : undefined;

                if (Array.isArray(result.data)) {
                    for (let pinData of result.data) {
                        if (bounds === undefined)
                            bounds = L.latLngBounds([pinData.lat, pinData.lng], [pinData.lat, pinData.lng]);
                        else bounds.extend([pinData.lat, pinData.lng]);
                    }
                }
                if (mapRef && mapRef.current && bounds != undefined) {
                    dispatch(setMapBounds({ bounds: bounds.toBBoxString() }));
                    mapRef.current?.fitBounds(bounds);
                }
            }
        });
    }

    function fetchWeatheStatePins() {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DASHBOARD.WEATHER_SATE_MAPPINS).then((result) => {
            if (result && result.status == RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    setWeatherStateItems(result.data);
                }

                var bounds: L.LatLngBounds | undefined = !showOnlyCategory ? mapRef.current?.getBounds() : undefined;

                if (Array.isArray(result.data)) {
                    for (let pinData of result.data) {
                        if (bounds === undefined)
                            bounds = L.latLngBounds([pinData.lat, pinData.lng], [pinData.lat, pinData.lng]);
                        else bounds.extend([pinData.lat, pinData.lng]);
                    }
                }
                if (mapRef && mapRef.current && bounds != undefined) {
                    dispatch(setMapBounds({ bounds: bounds.toBBoxString() }));
                    mapRef.current?.fitBounds(bounds);
                }
            }
        });
    }

    /* function isStatic() {
        if (dataGrid.static === undefined) {
            return true;
        }
        if (typeof dataGrid.static == 'boolean') {
            return dataGrid.static;
        }
        return false;
    } */

    const clearFilter = () => {
        dispatch(filterReset());
        mapFeatureRef.current.eachLayer(function (layer: any) {
            mapFeatureRef.current.removeLayer(layer);
        });
    };

    const onDeleted = (e: any) => {
        setLayerId(null);
        dispatch(filterReset());
    };

    function removeLayers() {
        if (mapFeatureRef != null) {
            const drawnItems = mapFeatureRef.current._layers;
            if (Object.keys(drawnItems).length > 1) {
                Object.keys(drawnItems).forEach((layerid, index) => {
                    if (index > 0) {
                        return;
                    }
                    const layer = drawnItems[layerid];
                    mapFeatureRef.current.removeLayer(layer);
                });
            }
        }
    }

    const onCreated = (e: any) => {
        removeLayers();
        dispatch(
            setMapSelectedBounds({
                latNorthEast: e.layer._bounds._northEast.lat,
                lngNorthEast: e.layer._bounds._northEast.lng,
                latSouthWest: e.layer._bounds._southWest.lat,
                lngSouthWest: e.layer._bounds._southWest.lng,
            })
        );
    };
    const onEdited = (e: any) => {
        let layer: any = Object.values(e.layers._layers)[0];
        dispatch(
            setMapSelectedBounds({
                latNorthEast: layer._bounds._northEast.lat,
                lngNorthEast: layer._bounds._northEast.lng,
                latSouthWest: layer._bounds._southWest.lat,
                lngSouthWest: layer._bounds._southWest.lng,
            })
        );
    };

    return (
        <div>
            <MapContainer
                whenCreated={(mapInstance: any) => {
                    mapRef.current = mapInstance;
                }}
                ref={mapRef}
                {...(mapOptions as any)}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />

                {showWatermeters && showWatermeterOn && <DevicePins items={items} statusChanged={statusChanged} />}
                {showLights && showLightsOn && <LightPins items={lightItems} statusChanged={[]} />}
                {showEnergymeters && showEnergyOn && <EnergyDevicesPins items={energyItems} statusChanged={[]} />}
                {showWeatherstate && showWeatherStateOn && (
                    <WeatherStateDevicesPins items={weatherStateItems} statusChanged={[]} />
                )}
                <FeatureGroup ref={mapFeatureRef}>
                    <EditControl
                        position="topright"
                        onEdited={onEdited}
                        onCreated={onCreated}
                        onDeleted={onDeleted}
                        draw={{
                            polyline: false,
                            rectangle: true,
                            circlemarker: false,
                            circle: false,
                            polygon: false,
                            marker: false,
                        }}
                    />
                </FeatureGroup>
            </MapContainer>
            <div
                style={{
                    position: 'absolute',
                    left: '10px',
                    bottom: '50px',
                    zIndex: 1000,
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
                }}
            >
                <Tooltip title={geti18nText('dashboard.widget.map.popup.clearfilter')}>
                    <Button onClick={clearFilter}>{geti18nText('dashboard.widget.map.clearfilter')}</Button>
                </Tooltip>
            </div>
            {/* <div
                style={{
                    position: 'absolute',
                    left: '10px',
                    bottom: '15px',
                    zIndex: 2000,
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
                }}
            >
                <Tooltip
                    title={
                        isStatic()
                            ? geti18nText('dashboard.widget.map.unlock')
                            : geti18nText('dashboard.widget.map.lock')
                    }
                >
                    <Button
                        onClick={() => lock(elementKey, !isStatic())}
                        icon={isStatic() ? <DragOutlined /> : <LockOutlined />}
                    />
                </Tooltip>
            </div> */}

            <div
                style={{
                    position: 'absolute',
                    right: '10px',
                    bottom: '35px',
                    zIndex: 1000,
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
                }}
            >
                {showWatermeterOn && !showOnlyCategory && (
                    <Tooltip
                        // title={
                        //     showWatermeters == false
                        //         ? geti18nText('dashboard.widget.map.watermeter.show')
                        //         : geti18nText('dashboard.widget.map.watermeter.hide')
                        // }
                        title={geti18nText('dashboard.widget.map.button.showAlarms')}
                    >
                        <Button
                            className={showWatermeters ? 'custom-map-watermeter' : ''}
                            // onClick={() => dispatch(showWatermeterButton(!showWatermeters))}
                            onClick={() => history.push('waterMeterDashboard')}
                        >
                            <Badge count={alarmCount[DeviceCategoryType.WATERMETER]} offset={[-10, -10]}>
                                <div className="custom-map-icon">
                                    <img src="/watermeter.png" />
                                </div>
                            </Badge>
                        </Button>
                    </Tooltip>
                )}
                {showLightsOn && !showOnlyCategory && (
                    <Tooltip
                        // title={
                        //     showLights == false
                        //         ? geti18nText('dashboard.widget.map.light.show')
                        //         : geti18nText('dashboard.widget.map.light.hide')
                        // }
                        title={geti18nText('dashboard.widget.map.button.showAlarms')}
                    >
                        <Button
                            className={showLights ? 'custom-map-lights' : ''}
                            // onClick={() => dispatch(showLightButton(!showLights))}
                            onClick={() => history.push('lightsDashboard')}
                        >
                            <Badge count={alarmCount[DeviceCategoryType.LIGHTS]} offset={[-10, -10]}>
                                <div className="custom-map-icon">
                                    <img src="/light.png" />
                                </div>
                            </Badge>
                        </Button>
                    </Tooltip>
                )}
                {showEnergyOn && !showOnlyCategory && (
                    <Tooltip
                        // title={
                        //     showEnergymeters == false
                        //         ? geti18nText('dashboard.widget.map.energy.show')
                        //         : geti18nText('dashboard.widget.map.energy.hide')
                        // }
                        title={geti18nText('dashboard.widget.map.button.showAlarms')}
                    >
                        <Button
                            className={showEnergymeters ? 'custom-map-energy' : ''}
                            //onClick={() => dispatch(showEnergyButton(!showEnergymeters))}
                            onClick={() => history.push('energyDashboard')}
                        >
                            <Badge count={alarmCount[DeviceCategoryType.ENERGYMETER]} offset={[-10, -15]}>
                                <div className="custom-map-icon">
                                    <ThunderboltOutlined />
                                </div>
                            </Badge>
                        </Button>
                    </Tooltip>
                )}
                {showWeatherStateOn && !showOnlyCategory && (
                    <Tooltip
                        // title={
                        //     showEnergymeters == false
                        //         ? geti18nText('dashboard.widget.map.weather_state.show')
                        //         : geti18nText('dashboard.widget.map.weather_state.hide')
                        // }
                        title={geti18nText('dashboard.widget.map.button.showAlarms')}
                    >
                        <Button
                            className={showWeatherstate ? 'custom-map-weather-state' : ''}
                            // onClick={() => dispatch(showWeatherStateButton(!showWeatherstate))}
                            onClick={() => history.push('alarmSettings')}
                        >
                            <Badge count={alarmCount[DeviceCategoryType.WEATHER]} offset={[-10, -15]}>
                                <div className="custom-map-icon">
                                    <CloudServerOutlined />
                                </div>
                            </Badge>
                        </Button>
                    </Tooltip>
                )}
            </div>
        </div>
    );
};

interface iDevicePins {
    items: { [index: string]: any }[];
    statusChanged?: { [index: string]: string }[];
}

const LightPins: React.FC<iDevicePins> = ({ items, statusChanged = [] }) => {
    const { filterDevice, mapBounds, lightsActive, lightsAlarm } = useSelector((state: RootState) => state.dashboard);
    const dispatch = useDispatch();
    const [popUp, setPopUp] = useState<any>(null);
    function addPopup(e: any, item: any) {
        setPopUp({
            key: new Date().getTime,
            position: e.latlng,
            item: item,
        });
    }

    function showDetails(device: any) {
        dispatch(deviceLightDetailsOpen({ record: device, visible: true }));
    }

    function filterItems() {
        if (filterDevice) {
            const fItems = items.filter((item) => item.id && item.id === filterDevice) || [];
            return fItems;
        } else if (lightsActive) {
            const fItems = items.filter((item) => item.deviceStatus === 4) || [];
            return fItems;
        } else if (lightsAlarm) {
            const fItems =
                items.filter(
                    (item) => (item.lightAlarm && item.lightAlarm > 0) || (item.nodeAlarm && item.nodeAlarm > 0)
                ) || [];
            return fItems;
        } else {
            return items;
        }
    }

    return (
        <React.Fragment>
            <MarkerClusterGroup>
                {filterItems().map((item: { [index: string]: any }, index: number) => (
                    <Marker
                        key={index}
                        icon={iconGenerator('light', item, statusChanged)}
                        position={[item.lat, item.lng]}
                        eventHandlers={{ click: (e) => addPopup(e, item) }}
                    ></Marker>
                ))}
                {popUp && (
                    <Popup key={popUp.key} offset={[0, -30]} position={popUp.position} onClose={() => setPopUp(null)}>
                        {' '}
                        <b>{popUp.item.name}</b>
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.latitude')}: </b>
                        {popUp.item.lat}
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.Longitude')}: </b>
                        {popUp.item.lng}
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.waterusage')}: </b>
                        <FormatNumber value={popUp.item.electricEnergy}></FormatNumber>
                        <br />
                        <br />
                        <Button onClick={() => showDetails(popUp.item)}>
                            {geti18nText('dashboard.widget.map.popup.details')}
                        </Button>
                    </Popup>
                )}
            </MarkerClusterGroup>
        </React.Fragment>
    );
};

const EnergyDevicesPins: React.FC<iDevicePins> = ({ items, statusChanged = [] }) => {
    const { filterDevice, mapBounds } = useSelector((state: RootState) => state.dashboard);
    const dispatch = useDispatch();
    const [popUp, setPopUp] = useState<any>(null);
    function addPopup(e: any, item: any) {
        setPopUp({
            key: new Date().getTime,
            position: e.latlng,
            item: item,
        });
    }

    function showDetails(device: any) {
        dispatch(deviceEnergyDetailsOpen({ record: device, visible: true }));
    }

    function filterItems() {
        if (filterDevice) {
            const fItems = items.filter((item) => item.id && item.id === filterDevice) || [];
            return fItems;
            // } else if (lightsActive) {
            //     const fItems = items.filter((item) => item.deviceStatus === 4) || [];
            //     return fItems;
            // } else if (lightsAlarm) {
            //     return [];
        } else {
            return items;
        }
    }

    return (
        <React.Fragment>
            <MarkerClusterGroup>
                {filterItems().map((item: { [index: string]: any }, index: number) => (
                    <Marker
                        key={index}
                        icon={iconGenerator('energy', item, statusChanged)}
                        position={[item.lat, item.lng]}
                        eventHandlers={{ click: (e) => addPopup(e, item) }}
                    ></Marker>
                ))}
                {popUp && (
                    <Popup key={popUp.key} offset={[0, -30]} position={popUp.position} onClose={() => setPopUp(null)}>
                        {' '}
                        <b>{popUp.item.name}</b>
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.latitude')}: </b>
                        {popUp.item.lat}
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.Longitude')}: </b>
                        {popUp.item.lng}
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.aa')}: </b>
                        <FormatNumber value={popUp.item.na}></FormatNumber>
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.ab')}: </b>
                        <FormatNumber value={popUp.item.nb}></FormatNumber>
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.ac')}: </b>
                        <FormatNumber value={popUp.item.nc}></FormatNumber>
                        <br />
                        <br />
                        <Button onClick={() => showDetails(popUp.item)}>
                            {geti18nText('dashboard.widget.map.popup.details')}
                        </Button>
                    </Popup>
                )}
            </MarkerClusterGroup>
        </React.Fragment>
    );
};

const WeatherStateDevicesPins: React.FC<iDevicePins> = ({ items, statusChanged = [] }) => {
    const { filterDevice, mapBounds, weatherStateActive, weatherStateInactive } = useSelector(
        (state: RootState) => state.dashboard
    );
    const dispatch = useDispatch();
    const [popUp, setPopUp] = useState<any>(null);
    function addPopup(e: any, item: any) {
        setPopUp({
            key: new Date().getTime,
            position: e.latlng,
            item: item,
        });
    }

    function showDetailsWeatherState(device: any) {
        dispatch(deviceWeatherStateDetailsOpen({ record: device, visible: true }));
    }

    function filterItems() {
        if (filterDevice) {
            const fItems = items.filter((item) => item.id && item.id === filterDevice) || [];
            return fItems;
        } else if (weatherStateActive) {
            const fItems = items.filter((item) => item.alarmStatus != -48) || [];
            return fItems;
        } else if (weatherStateInactive) {
            const fItems = items.filter((item) => item.alarmStatus == -48) || [];
            return fItems;
        } else {
            return items;
        }
    }

    return (
        <React.Fragment>
            <MarkerClusterGroup>
                {filterItems().map((item: { [index: string]: any }, index: number) => (
                    <Marker
                        key={index}
                        icon={iconGenerator('weather', item, statusChanged)}
                        position={[item.lat, item.lng]}
                        eventHandlers={{ click: (e) => addPopup(e, item) }}
                    ></Marker>
                ))}
                {popUp && (
                    <Popup key={popUp.key} offset={[0, -30]} position={popUp.position} onClose={() => setPopUp(null)}>
                        {' '}
                        <b>{popUp.item.name}</b>
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.latitude')}: </b>
                        {popUp.item.lat}
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.Longitude')}: </b>
                        {popUp.item.lng}
                        <br />
                        <Button onClick={() => showDetailsWeatherState(popUp.item)}>
                            {geti18nText('dashboard.widget.map.popup.details')}
                        </Button>
                    </Popup>
                )}
            </MarkerClusterGroup>
        </React.Fragment>
    );
};

const DevicePins: React.FC<iDevicePins> = ({ items, statusChanged = [] }) => {
    const {
        filterActive,
        filterInactive,
        filterAlarmStatus,
        filterLowBattery,
        filterDevice,
        filterSource,
        mapBounds,
    } = useSelector((state: RootState) => state.dashboard);
    const [popUp, setPopUp] = useState<any>(null);
    const dispatch = useDispatch();

    function showDetails(device: any) {
        dispatch(deviceDetailsOpen({ record: device, visible: true }));
    }

    function filterItems() {
        if (filterActive) {
            const fItems = items.filter((item) => item.alarmStatus != -48) || [];
            getNewBounds(fItems);
            return fItems;
        } else if (filterInactive) {
            const fItems = items.filter((item) => item.alarmStatus == -48) || [];
            getNewBounds(fItems);
            return fItems;
        } else if (filterLowBattery) {
            const fItems = items.filter((item) => item.alarmStatus && item.alarmStatus == 1) || [];
            getNewBounds(fItems);
            return fItems;
        } else if (filterDevice) {
            const fItems = items.filter((item) => item.id && item.id === filterDevice) || [];
            getNewBounds(fItems);
            return fItems;
        } else if (filterAlarmStatus || filterSource) {
            let fItems: { [index: string]: any }[] = [];
            if (filterAlarmStatus) {
                fItems = items.filter((item) => item.alarmStatus && item.alarmStatus === filterAlarmStatus) || [];
            }
            if (filterSource) {
                if (filterAlarmStatus) {
                    fItems = fItems.filter((item) => item.source && item.source === filterSource) || [];
                } else {
                    fItems = items.filter((item) => item.source && item.source === filterSource) || [];
                }
            }
            getNewBounds(fItems);
            return fItems;
        } else {
            getNewBounds(items);
            return items;
        }
    }

    function getNewBounds(items: { [index: string]: any }[]) {
        var bounds: L.LatLngBounds | undefined = undefined;

        if (Array.isArray(items)) {
            for (let pinData of items) {
                if (bounds === undefined)
                    bounds = L.latLngBounds([pinData.lat, pinData.lng], [pinData.lat, pinData.lng]);
                else bounds.extend([pinData.lat, pinData.lng]);
            }
        }
        if (bounds != undefined && mapBounds != bounds.toBBoxString()) {
            //   dispatch(setMapBounds({ bounds: bounds.toBBoxString() }));
        }
    }

    function addPopup(e: any, item: any) {
        setPopUp({
            key: new Date().getTime,
            position: e.latlng,
            item: item,
        });
    }
    return (
        <React.Fragment>
            <MarkerClusterGroup>
                {filterItems().map((item: { [index: string]: any }, index: number) => (
                    <Marker
                        key={index}
                        icon={iconGenerator('watermeter', item, statusChanged)}
                        position={[item.lat, item.lng]}
                        eventHandlers={{ click: (e) => addPopup(e, item) }}
                        //onClick={(e: any) => addPopup(e, item)}
                    ></Marker>
                ))}
                {popUp && (
                    <Popup key={popUp.key} offset={[0, -30]} position={popUp.position} onClose={() => setPopUp(null)}>
                        {' '}
                        <b>{popUp.item.name}</b>
                        <br />
                        {popUp.item.alarmStatus != 0 && (
                            <React.Fragment>
                                <br />
                                <b>{geti18nText('dashboard.widget.map.popup.alarm')}: </b>
                                {geti18nText(
                                    'app.enum.ALARM_STATUS.' +
                                        GetEnumNameForValue('ALARM_STATUS', popUp.item.alarmStatus)
                                )}
                            </React.Fragment>
                        )}
                        {(popUp.item.source == 100 ||
                            popUp.item.source == 200 ||
                            popUp.item.source == 300 ||
                            popUp.item.source == 400) && (
                            <React.Fragment>
                                <br />
                                <b>{geti18nText('dashboard.widget.map.popup.source')}: </b>
                                {geti18nText(
                                    'app.enum.PAYLOAD_TYPE.' + GetEnumNameForValue('PAYLOAD_TYPE', popUp.item.source)
                                )}
                            </React.Fragment>
                        )}
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.address')}: </b>
                        {popUp.item.address}
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.latitude')}: </b>
                        {popUp.item.lat}
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.Longitude')}: </b>
                        {popUp.item.lng}
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.waterusage')}: </b>
                        {popUp.item.waterConsumption ? NyUtils.formatNumber(popUp.item.waterConsumption, 3) : ''} m3
                        <br />
                        <br />
                        <Button onClick={() => showDetails(popUp.item)}>
                            {geti18nText('dashboard.widget.map.popup.details')}
                        </Button>
                    </Popup>
                )}
            </MarkerClusterGroup>
        </React.Fragment>
    );
};

export const iconGenerator = (
    type: 'gateway' | 'watermeter' | 'light' | 'energy' | 'weather',
    item: { [index: string]: any },
    statusChanged: { [index: string]: any }[]
) => {
    let color: string = '#FF0000';
    let icon = 'watermeter.png';
    let name = '';

    if (type == 'gateway') {
        icon = 'gtway.png';
        color = '#000000';
        name =
            item.mac != null
                ? item.mac
                : item.name
                ? item.name.substr(0, Math.min(item.name.length, 17)) + (item.name.length > 17 ? '...' : '')
                : '';
    } else if (type == 'watermeter') {
        name = item.name;

        const changedStatus = statusChanged.find(({ id }) => id === item.id);
        if (changedStatus != undefined) {
            if (!changedStatus.active) {
                color = '#FF0000';
            } else {
                color = '#00a900';
            }
        } else {
            if (!item.hasOwnProperty('alarmStatus')) {
                color = '#FF0000';
            } else if (item.alarmStatus == -48) {
                color = '#FF0000';
            } else {
                color = getSourceColor(item.source);
            }
        }
    } else if (type == 'light') {
        icon = 'light.png';
        color = '#FFD700';
        name =
            item.mac != null
                ? item.mac
                : item.name
                ? item.name.substr(0, Math.min(item.name.length, 17)) + (item.name.length > 17 ? '...' : '')
                : '';
    } else if (type == 'energy') {
        icon = 'energy.png';
        color = '#FFA500';
        name =
            item.name != null
                ? item.name.substr(0, Math.min(item.name.length, 17)) + (item.name.length > 17 ? '...' : '')
                : '';
    } else if (type == 'weather') {
        icon = 'weather.png';
        color = item.alarmStatus === GetEnum('ALARM_STATUS').INACTIVE ? '#EA0D01' : '#387e19';
        name =
            item.name != null
                ? item.name.substr(0, Math.min(item.name.length, 17)) + (item.name.length > 17 ? '...' : '')
                : '';
    }

    return L.divIcon({
        className: 'custom-div-icon',
        html:
            "<div class='title' style='border-color: " +
            color +
            '; color: ' +
            color +
            "'>" +
            name +
            "</div><div style='background-color:" +
            color +
            ";' class='marker-pin'></div><img src='/" +
            icon +
            "' />",
        iconSize: [30, 60],
        iconAnchor: [15, 60],
    });
};

const getSourceColor = (source: number) => {
    switch (source) {
        case 200:
            return '#1890ff';
            break;
        case 300:
            return '#ff9900';
            break;
        case 400:
            return '#cc33ff';
            break;
        default:
            return '#00a900';
    }
};

export default WidgetMap;
