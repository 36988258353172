import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Card, Statistic, Form } from 'antd';
import { LineChartOutlined, ThunderboltOutlined, WarningOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { geti18nText, NyRequestResolver, NySearchField, NyUtils, RESPONSE } from '@nybble/nyreact';
import { WindDirectionDescription } from '../../../../utils/Constants';

const { Title } = Typography;
interface IAirCard {
    solarRadiation?: number;
    pm_10?: number;
    pm_25?: number;
    avgNoise?: number;
    lux?: number;
}
const AirCard: React.FC<IAirCard> = ({ solarRadiation = 0, pm_10 = 0, pm_25 = 0, avgNoise = 0, lux = 0 }) => {
    const styles: any = {
        row: {
            alignItems: 'center',
            height: '100%',
            width: '100%',
            backgroundImage: 'linear-gradient(to bottom right, #93c7ff, #808080)',
        },

        statistic: {
            margin: '20px',
        },
    };

    return (
        <React.Fragment>
            <Card style={styles.row} /*onClick={() => setParName(null)}*/>
                <Row>
                    <Col style={styles.statistic}>
                        <Statistic
                            title={geti18nText('weatherStateDetails.widget.title.pm_10')}
                            value={pm_10}
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                            valueStyle={{
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: 'white',
                            }}
                            suffix={'ug/m3'}
                        />
                        <Statistic
                            title={geti18nText('weatherStateDetails.widget.title.pm_25')}
                            value={pm_25}
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                            valueStyle={{
                                //color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: 'white',
                            }}
                            suffix={'ug/m3'}
                        />
                        <Statistic
                            title={geti18nText('weatherStateDetails.widget.title.lux')}
                            value={lux}
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                            valueStyle={{
                                //color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: 'white',
                            }}
                            suffix={'LUX'}
                        />

                        <Statistic
                            title={geti18nText('weatherStateDetails.widget.title.noise')}
                            value={avgNoise}
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                            valueStyle={{
                                //color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: 'white',
                            }}
                            suffix={'dB'}
                        />

                        <Statistic
                            title={geti18nText('weatherStateDetails.widget.title.solarRadiation')}
                            value={solarRadiation}
                            style={{
                                fontSize: '20px',
                                fontWeight: 'bold',
                                color: 'black',
                            }}
                            valueStyle={{
                                //color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                fontSize: '16px',
                                fontWeight: 'bold',
                                color: 'white',
                            }}
                            suffix={'W/m2'}
                        />
                    </Col>
                </Row>
            </Card>
        </React.Fragment>
    );
};

export default AirCard;
