import { ArrowDownOutlined, ArrowUpOutlined, ExperimentOutlined, FireOutlined } from '@ant-design/icons';
import { NyRequestResolver, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Button, Checkbox, Popover, Progress, Row } from 'antd';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import {
    deviceWeatherStateDetailsChartOpen,
    deviceWeatherStateDetailsOpen,
} from '../../../slices/deviceWeatherStateDetailsReducer';
import moment from 'moment';
import { calculatePercent, calculateProgressGradient, cardslabel, formLabelCard } from './cardsLabel';

const SelectedWeatherlabel: React.FC = (props: any) => {
    const [visibleSettings, setVisibleSettings] = useState(false);
    const [checkedCards, setCheckedCards] = useState<any>([]);
    const [selectedDevice, setSelectedDevice] = useState<any>(undefined);
    const { selectedIdToShowDetail } = useSelector((state: RootState) => state.dashboard);
    const [data, setData] = useState<any>([]);
    const [loading, setLoading] = useState(true);
    const [userWidgets, setUserWidgets] = useState<any>(props.userWidgets);
    const dispatch = useDispatch();
    const gridRef = useRef<any>();

    useEffect(() => {
        fetchWeatherStatePins();
        let widgetData = userWidgets;
        if (widgetData != undefined) {
            widgetData.selectedDevice = selectedIdToShowDetail;
            setUserWidgets(widgetData);
        } else {
            widgetData = {};
            widgetData.selectedDevice = selectedIdToShowDetail;
            setUserWidgets(widgetData);
        }

        NyRequestResolver.requestPost(
            CONSTANTS_REQ.USER.WEATHER_WIDGETS,
            undefined,
            { widgets: JSON.stringify(widgetData) },
            false
        );
    }, [selectedIdToShowDetail]);

    async function fetchWeatherStatePins() {
        setLoading(true);
        console.log('tmpSelectedID', selectedIdToShowDetail);
        let responseFetchPins = await NyRequestResolver.requestGet(CONSTANTS_REQ.DASHBOARD.WEATHER_SATE_MAPPINS);
        if (responseFetchPins && responseFetchPins.status == RESPONSE.OK) {
            if (Array.isArray(responseFetchPins.data)) {
                let tmpSelectedId = selectedIdToShowDetail ? selectedIdToShowDetail : responseFetchPins.data[0];

                if (userWidgets && userWidgets.selectedDevice) {
                    tmpSelectedId = userWidgets.selectedDevice;
                }
                let selectedIdFromPin = responseFetchPins.data.find((res: any) => res.id === tmpSelectedId.id);
                setSelectedDevice(selectedIdFromPin != undefined ? selectedIdFromPin : tmpSelectedId);
                let result = await NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.DEVICE_CONSUMTION_WITH_LIMIT, {
                    deviceId: tmpSelectedId.id,
                });
                if (result.status === RESPONSE.OK && result.data) {
                    let resultData: any = result.data;
                    resultData = resultData.map((data: any) => {
                        let item = cardslabel.find((card: any) => card.key === data.key);
                        data.chartValue = data.value;
                        delete data.value;
                        if (item) data = Object.assign(data, item);
                        return data;
                    });
                    if (userWidgets && userWidgets.checkedCard) {
                        setCheckedCards(userWidgets.checkedCard);
                    } else
                        setCheckedCards(
                            resultData.filter((f: any) => f.key !== 'INACTIVE').map((rData: any) => rData.key)
                        );
                    setData(resultData);
                }
            }
        }
        setLoading(false);
    }

    const onChange = (values: any) => {
        setCheckedCards(values);
    };

    const onVisibleChange = (visible: any) => {
        setVisibleSettings(visible);
        if (!visible) {
            let widgetData = userWidgets;
            widgetData.checkedCard = checkedCards;
            setUserWidgets(widgetData);
            NyRequestResolver.requestPost(
                CONSTANTS_REQ.USER.WEATHER_WIDGETS,
                undefined,
                { widgets: JSON.stringify(widgetData) },
                false
            );
        }
    };

    return loading ? (
        <NySpinner />
    ) : (
        <div style={{ padding: 10 }}>
            <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 15 }}>
                {selectedDevice && (
                    <div style={{ fontSize: 16, width: '50%', fontWeight: 'bold' }}>
                        {selectedDevice.name +
                            ' - ' +
                            selectedDevice.serialNumber +
                            ' - ' +
                            moment(selectedDevice.ts).format('DD.MM.yyyy HH:mm')}
                    </div>
                )}

                <div style={{ justifyContent: 'flex-end', display: 'flex', width: '100%' }}>
                    <Popover
                        visible={visibleSettings}
                        onVisibleChange={onVisibleChange}
                        placement="bottom"
                        overlayStyle={{ width: '20%' }}
                        content={
                            <div>
                                <Row gutter={[12, 12]}>
                                    <Checkbox.Group
                                        defaultValue={checkedCards}
                                        //label={this.state.categoryType}
                                        onChange={onChange}
                                        options={cardslabel}
                                    />
                                </Row>
                                <Button type="link" onClick={() => {}}>
                                    {geti18nText('dashboard.widget.map.clearfilter')}
                                </Button>
                            </div>
                        }
                        trigger="click"
                    >
                        <Button type="primary" shape="round" icon={<ExperimentOutlined />} />
                    </Popover>
                </div>
            </div>
            <div ref={gridRef} style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                {data &&
                    data.map((card: any) => {
                        return (
                            checkedCards.includes(card.key) && (
                                <div
                                    style={{
                                        flex: '0 1 300px',
                                        flexDirection: 'row',
                                        display: 'flex',
                                        padding: '10px',
                                        cursor: 'pointer',
                                    }}
                                    onClick={() => {
                                        console.log('Otvaram ', {
                                            chartRecord: selectedDevice,
                                            chartVisible: true,
                                            chartParmKey: card.chartKey,
                                        });
                                        dispatch(
                                            deviceWeatherStateDetailsChartOpen({
                                                chartRecord: selectedDevice,
                                                chartVisible: true,
                                                chartParmKey: card.chartKey,
                                            })
                                        );
                                    }}
                                >
                                    <div style={{ position: 'relative', width: '100px', height: '150px' }}>
                                        {card.icon}
                                        <div style={{ position: 'absolute' }}>
                                            <Progress
                                                type="dashboard"
                                                format={(percent) => ``}
                                                strokeColor={calculateProgressGradient(card)}
                                                gapDegree={120}
                                                strokeLinecap={'square'}
                                                percent={calculatePercent(card)}
                                            />
                                            <div style={{ display: 'flex', flexDirection: 'row', width: '250px' }}>
                                                {card.showAlarm === true && card.lowerLimit && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <span>
                                                            {geti18nText('weatherStateDetails.graph.alarm')}
                                                            {':  '}
                                                        </span>
                                                        <ArrowDownOutlined
                                                            style={{
                                                                fontSize: '16px',
                                                                color: '#108ee9',
                                                                marginLeft: '3px',
                                                            }}
                                                        />
                                                        <span>{card.lowerLimit + ' ' + card.unit}</span>
                                                    </div>
                                                )}

                                                {card.showAlarm === true && card.upperLimit && (
                                                    <div
                                                        style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            alignItems: 'center',
                                                            marginLeft: card.lowerLimit ? '30px' : '0px',
                                                        }}
                                                    >
                                                        <span>
                                                            {geti18nText('weatherStateDetails.graph.alarm')}
                                                            {':  '}
                                                        </span>
                                                        <ArrowUpOutlined
                                                            style={{
                                                                fontSize: '16px',
                                                                color: '#a3264b',
                                                                marginLeft: '3px',
                                                            }}
                                                        />
                                                        <span>{card.upperLimit + ' ' + card.unit}</span>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div></div>
                                    </div>
                                    <div style={{ marginLeft: 20 }}>
                                        <div
                                            style={{
                                                fontSize: 16,
                                                color: formLabelCard(card),
                                                fontWeight: 'bold',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {card.label}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                                            <div
                                                style={{
                                                    fontSize: 28,
                                                    marginLeft: 10,
                                                    color: formLabelCard(card),
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {card.chartValue ? card.chartValue : 0}
                                            </div>
                                            <div
                                                style={{ fontSize: 18, fontWeight: 'bold', color: formLabelCard(card) }}
                                            >
                                                {card.unit}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        );
                    })}
            </div>
        </div>
    );
};

export default SelectedWeatherlabel;
