import { geti18nText } from '@nybble/nyreact';

export const getUnreadAlarmFilterType: any = () => {
    let types = [
        { id: 1, text: geti18nText('dashboard.widget.alarm.unread.filter.unread') },
        { id: 0, text: geti18nText('dashboard.widget.alarm.unread.filter.read') },
    ];

    return types;
};
