import { NyRequestResolver, NySession, RESPONSE } from '@nybble/nyreact';
import { CONSTANTS_REQ, APPLICATION_KEY } from './Constants';
import jwt_decode from 'jwt-decode';

export const GetEnum = function (
    enumName:
        | 'ALARM_STATUS'
        | 'PAYLOAD_TYPE'
        | 'DEVICE_STATUS'
        | 'TASK_STATUS'
        | 'TASK_TYPE'
        | 'APPLICATION_TYPE'
        | 'IMPORT_EXPORT_TYPE'
        | 'IMPORT_EXPORT_STATUS'
        | 'DEVICE_CATEGORY'
        | 'PAYLOAD_STATUS_ACTION'
        | 'TERMINAL_NODE_ALARM'
        | 'LAMP_ALARM'
        | 'LIGHTS_ALARM_TYPE'
        | 'APPLICATION_SETTINGS_DATA_TYPE'
) {
    const en = loadFromSession(APPLICATION_KEY + 'enums');

    if (en !== undefined && en[enumName] !== undefined) {
        return en[enumName];
    } else {
        return undefined;
    }
};

export const LoadEnums = async function () {
    await NyRequestResolver.requestGet(CONSTANTS_REQ.ENUMS).then((response: any) => {
        if (response.status === RESPONSE.OK) {
            sessionStorage.setItem(APPLICATION_KEY + 'enums', JSON.stringify(response.data));
        }
    });
};

export const GetEnumNameForValue = function (
    name:
        | 'ALARM_STATUS'
        | 'PAYLOAD_TYPE'
        | 'DEVICE_STATUS'
        | 'TASK_STATUS'
        | 'TASK_TYPE'
        | 'APPLICATION_TYPE'
        | 'IMPORT_EXPORT_TYPE'
        | 'IMPORT_EXPORT_STATUS'
        | 'DEVICE_CATEGORY'
        | 'PAYLOAD_STATUS_ACTION'
        | 'TERMINAL_NODE_ALARM'
        | 'LAMP_ALARM'
        | 'LIGHTS_ALARM_TYPE'
        | 'APPLICATION_SETTINGS_DATA_TYPE',
    value: any
) {
    const enums = GetEnum(name);
    if (enums === undefined) {
        return undefined;
    }

    for (const [key, enumValue] of Object.entries(enums)) {
        if (enumValue === value) {
            return key;
        }
    }

    return undefined;
};

function loadFromSession(key: string) {
    const values = sessionStorage.getItem(key);
    if (values && values !== 'undefined') {
        return JSON.parse(values);
    }
    return undefined;
}

export function getUserCategoryType() {
    var token = NySession.getUserToken();
    if(token){
        const userToken: any = jwt_decode(token);
        return userToken && userToken.user_detail && userToken.user_detail.categoryType
            ? userToken.user_detail.categoryType
            : undefined;
    }
    return undefined;
}
