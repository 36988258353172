import { List } from 'antd';
import React, { useEffect, useState } from 'react';
import { RootState } from '../../rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import { CheckCircleFilled, DeleteOutlined, MinusCircleFilled, PushpinFilled } from '@ant-design/icons';
import {
    removeFromWeatherList,
    setSelectedDeviceGroupId,
    setSelectedIdToShowDetails,
} from '../../slices/dashboardSlice';
import { NyRequestResolver, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { CONSTANTS_REQ, DeviceCategoryType } from '../../utils/Constants';

const WeatherGroupList = (props: any) => {
    const { selectedDeviceGroupId } = useSelector((state: RootState) => state.dashboard);
    const dispatch = useDispatch();
    const [userWidgets, setUserWidgets] = useState<any>(props.userWidgets);
    const [listData, setListData] = useState<any>(undefined);
    const [loading, setLoading] = useState(true);
    const [selected, setSelected] = useState<any>(undefined);

    useEffect(() => {
        const fetchData = async () => {
            const setDefaultFilterValue = () => {
                return [{ field: 'active', condition: 'equals_bool', value: 1 }];
            };
            NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE_GROUP.LIST_ALL, {
                search: encodeURI(JSON.stringify(setDefaultFilterValue())),
                type: DeviceCategoryType.WEATHER,
            }).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        setListData(result.data);
                    }
                }
            });
        };

        fetchData();
        setLoading(false);
    }, []);

    const selectGroup = (id: any) => {
        let sel = id;
        if (id == selected) {
            sel = undefined;
        }
        setSelected(sel);
        dispatch(setSelectedDeviceGroupId(sel));
    };

    return loading ? (
        <NySpinner />
    ) : (
        <List
            header={
                <div className="weather-group-list-title">{geti18nText('dashboard.widget.weather.groups.title')}</div>
            }
            itemLayout="horizontal"
            dataSource={listData}
            style={{ maxHeight: '60vh', overflow: 'auto' }}
            className="thin-scrollbar"
            size="small"
            renderItem={(item: any) => {
                return (
                    <List.Item
                        style={{ padding: 15 }}
                        className={selectedDeviceGroupId == item.id ? 'selected-item' : 'weather-group-list-item'}
                    >
                        <List.Item.Meta
                            title={
                                <a
                                    onClick={() => {
                                        selectGroup(item.id);
                                    }}
                                >
                                    <div className="weather-group-list-item-title">{item.name}</div>
                                </a>
                            }
                        />
                    </List.Item>
                );
            }}
        />
    );
};

export default WeatherGroupList;
