import React from 'react';

import { useSelector } from 'react-redux';

import { getColumnSearch, geti18nText, NyDataTable, NyUtils } from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import { CONSTANTS_REQ, WindDirectionDescription } from '../../../utils/Constants';
import PayloadWeatherStateHistoryEdit from './PayloadWeatherStateHistoryEdit';

const PayloadWeatherStateHistoryTable = () => {
    const { record } = useSelector((state: RootState) => state.deviceWeatherStateDetails);

    const setDefaultFilterValue = () => {
        return [{ field: 'deviceId', condition: 'equals', value: record.id }];
    };

    const csvCustomizationColumns: any = () => {
        return [
            {
                'deviceConsumption.ts': (value: any) => {
                    if (value) {
                        const ts: Date = new Date(value);
                        return ts.toLocaleString(NyUtils.getSelectedLocale());
                    }
                    return '';
                },
            },

            {
                'deviceConsumption.avgWindDirection': (value: any) => {
                    return value ? value + '°' : '';
                },
            },

            {
                'deviceConsumption.avgWindSpeed': (value: any) => {
                    return value ? value + '°' : '';
                },
            },
            {
                'deviceConsumption.temp': (value: any) => {
                    return value ? value + '°C' : '';
                },
            },
            {
                'deviceConsumption.humidity': (value: any) => {
                    return value ? value + '%' : '';
                },
            },
            {
                'deviceConsumption.airPressure': (value: any) => {
                    return value ? value + ' HPa' : '';
                },
            },
            {
                'deviceConsumption.pm_10': (value: any) => {
                    return value ? value + ' ug/m3' : '';
                },
            },
            {
                'deviceConsumption.pm_25': (value: any) => {
                    return value ? value + ' ug/m3' : '';
                },
            },
        ];
    };

    return (
        <React.Fragment>
            <NyDataTable
                headerTitle={geti18nText('weatherStatePayloadHistory.table.header')}
                url={CONSTANTS_REQ.DEVICE.PAYLOAD_WEATHER_STATE_HISTORY}
                showRecordModal={true}
                scroll={{ x: 1200 }}
                exportCSV={true}
                hideButtons={true}
                setDefaultFilterValue={setDefaultFilterValue}
                colCSVCustomization={csvCustomizationColumns()}
                showRowSelection={false}
                modalComponent={PayloadWeatherStateHistoryEdit}
                columns={[
                    {
                        title: geti18nText('weatherStatePayloadHistory.table.id'),
                        dataIndex: 'id',
                        width: '10%',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },

                    {
                        title: geti18nText('weatherStatePayloadHistory.table.windDirection'),
                        dataIndex: ['deviceConsumption', 'avgWindDirection'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {WindDirectionDescription(
                                        record.deviceConsumption?.avgWindDirection,
                                        record.deviceConsumption?.avgWindSpeed
                                    )}
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('weatherStatePayloadHistory.table.windSpeed'),
                        dataIndex: ['deviceConsumption', 'avgWindSpeed'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.avgWindSpeed
                                        ? NyUtils.formatNumber(record.deviceConsumption?.avgWindSpeed, 2)
                                        : '0,0'}
                                    &nbsp;m/s
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('weatherStatePayloadHistory.table.temp'),
                        dataIndex: ['deviceConsumption', 'temp'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.temp
                                        ? NyUtils.formatNumber(record.deviceConsumption?.temp, 2)
                                        : '0'}
                                    &nbsp;°C
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('weatherStatePayloadHistory.table.humidity'),
                        dataIndex: ['deviceConsumption', 'humidity'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.humidity
                                        ? NyUtils.formatNumber(record.deviceConsumption?.humidity, 2)
                                        : '0'}
                                    &nbsp;%
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('weatherStatePayloadHistory.table.airPressure'),
                        dataIndex: ['deviceConsumption', 'airPressure'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.airPressure
                                        ? NyUtils.formatNumber(record.deviceConsumption?.airPressure, 2)
                                        : '0'}
                                    &nbsp;HPa
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },

                    {
                        title: geti18nText('weatherStatePayloadHistory.table.solarRadiation'),
                        dataIndex: ['deviceConsumption', 'solarRadiation'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.solarRadiation
                                        ? NyUtils.formatNumber(record.deviceConsumption?.solarRadiation, 2)
                                        : '0'}
                                    &nbsp;
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('weatherStatePayloadHistory.table.UVIntensity'),
                        dataIndex: ['deviceConsumption', 'uv_intensity'],

                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.uv_intensity
                                        ? NyUtils.formatNumber(record.deviceConsumption?.uv_intensity, 2)
                                        : '0'}
                                    &nbsp;
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('weatherStatePayloadHistory.table.sunshine_hours'),
                        dataIndex: ['deviceConsumption', 'sunshineHours'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.sunshineHours
                                        ? NyUtils.formatNumber(record.deviceConsumption?.sunshineHours, 2)
                                        : '0'}
                                    &nbsp;min
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('weatherStatePayloadHistory.table.lux'),
                        dataIndex: ['deviceConsumption', 'lux'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.lux
                                        ? NyUtils.formatNumber(record.deviceConsumption?.lux, 2)
                                        : '0'}
                                    &nbsp;LUX
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('weatherStatePayloadHistory.table.noise'),
                        dataIndex: ['deviceConsumption', 'avgNoise'],

                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.avgNoise
                                        ? NyUtils.formatNumber(record.deviceConsumption?.avgNoise, 2)
                                        : '0'}
                                    &nbsp;dB
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('weatherStatePayloadHistory.table.pm_25'),
                        dataIndex: ['deviceConsumption', 'pm_25'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.pm_25
                                        ? NyUtils.formatNumber(record.deviceConsumption?.pm_25, 2)
                                        : '0'}
                                    &nbsp;ug/m3
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('weatherStatePayloadHistory.table.pm_10'),
                        dataIndex: ['deviceConsumption', 'pm_10'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.pm_10
                                        ? NyUtils.formatNumber(record.deviceConsumption?.pm_10, 2)
                                        : '0'}
                                    &nbsp;ug/m3
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('weatherStatePayloadHistory.table.soil_temperature'),
                        dataIndex: ['deviceConsumption', 'soilTemperature'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.soilTemperature
                                        ? NyUtils.formatNumber(record.deviceConsumption?.soilTemperature, 2)
                                        : '0'}
                                    &nbsp;°C
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },
                    {
                        title: geti18nText('weatherStatePayloadHistory.table.soil_moisture'),
                        dataIndex: ['deviceConsumption', 'soilMoisture'],
                        render: (text: string, record: { [index: string]: any }) => {
                            return (
                                <div>
                                    {record.deviceConsumption?.soilMoisture
                                        ? NyUtils.formatNumber(record.deviceConsumption?.soilMoisture, 2)
                                        : '0'}
                                    &nbsp;%
                                </div>
                            );
                        },

                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                    },

                    {
                        title: geti18nText('weatherStatePayloadHistory.table.ts'),
                        dataIndex: ['deviceConsumption', 'ts'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('number'),
                        render: (text: string, record: { [index: string]: any }) => {
                            if (record.deviceConsumption?.ts) {
                                const ts: Date = new Date(record.deviceConsumption.ts);
                                return <div>{ts.toLocaleString(NyUtils.getSelectedLocale())}</div>;
                            }
                        },
                    },

                    {
                        title: geti18nText('weatherStatePayloadHistory.table.payload'),
                        dataIndex: 'payload',
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                    {
                        title: geti18nText('weatherStatePayloadHistory.table.mac'),
                        dataIndex: ['payloadInfo', 'mac'],
                        sorter: (a: any, b: any) => {},
                        ...getColumnSearch('string'),
                    },
                ]}
            />
        </React.Fragment>
    );
};

export default PayloadWeatherStateHistoryTable;
