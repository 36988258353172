import { Button, Input, List, Space } from 'antd';
import React, { useEffect, useState } from 'react';
import { RootState } from '../../rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import {
    CheckCircleFilled,
    DeleteOutlined,
    FormOutlined,
    MinusCircleFilled,
    PushpinFilled,
    SearchOutlined,
    UnorderedListOutlined,
} from '@ant-design/icons';
import { removeFromWeatherList, setSelectedIdToShowDetails } from '../../slices/dashboardSlice';
import { NyRequestResolver, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { CONSTANTS_REQ, DeviceCategoryType } from '../../utils/Constants';
import { GetEnum } from '../../utils/Enums';
import moment from 'moment';
import { deviceDetailsOpen } from '../../slices/deviceDetailsSlice';
import { deviceWeatherStateDetailsOpen } from '../../slices/deviceWeatherStateDetailsReducer';

const WeatherList = (props: any) => {
    const { selectedIdToShowDetail, selectedDeviceGroupId, queryId, searchRefresh, mapSelectedBounds } = useSelector(
        (state: RootState) => state.dashboard
    );
    const dispatch = useDispatch();

    const [listData, setListData] = useState<any>(undefined);
    const [searchList, setSearchList] = useState<any>(undefined);
    const [loading, setLoading] = useState(true);
    const [searchValue, setSearchValue] = useState<any>();

    useEffect(() => {
        const fetchData = async () => {
            let item: any = {
                search: encodeURI(JSON.stringify(setDefaultFilterValue())),
                type: DeviceCategoryType.WEATHER,
            };
            if (mapSelectedBounds != undefined) {
                item.latNorthEast = mapSelectedBounds.latNorthEast;
                item.lngNorthEast = mapSelectedBounds.lngNorthEast;
                item.latSouthWest = mapSelectedBounds.latSouthWest;
                item.lngSouthWest = mapSelectedBounds.lngSouthWest;
            }
            NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.LIST_ALL, item).then((result: any) => {
                if (result.status === RESPONSE.OK) {
                    if (result.data) {
                        setListData(result.data);
                        setSearchList(result.data);
                        //setDefaultData(result.data);
                    }
                }
            });
        };

        const setDefaultFilterValue = () => {
            let array = [{ field: 'active', condition: 'equals_bool', value: 1 }];
            if (queryId != undefined && queryId != 0) {
                array.push({ field: 'category_id', condition: 'equals', value: queryId });
            }

            if (selectedDeviceGroupId != undefined) {
                array.push({ field: '_d.device_group_id', condition: 'equals', value: selectedDeviceGroupId });
            }
            return array;
        };
        setSelectedIdToShowDetails(undefined);
        fetchData();
        setLoading(false);

        console.log(selectedDeviceGroupId);
    }, [selectedDeviceGroupId, queryId, mapSelectedBounds]);

    useEffect(() => {
        setSearchValue(undefined);
        setSearchList(listData);
    }, [searchRefresh]);

    const onSearch = (e: any) => {
        console.log(e.target.value);

        let value = e.target.value.toLowerCase();
        let filter = listData.filter((x: any) => x.name.toLowerCase().includes(value));
        setSearchValue(e.target.value);
        setSearchList(filter);
    };
    function showDetails(device: any) {
        dispatch(deviceWeatherStateDetailsOpen({ record: device, visible: true }));
    }

    return loading ? (
        <NySpinner />
    ) : (
        <List
            header={
                <div>
                    <div className="weather-group-list-title">
                        {geti18nText('dashboard.widget.weather.devices.title')}
                    </div>

                    <Input
                        value={searchValue}
                        onChange={onSearch}
                        placeholder={geti18nText('dashboard.widget.weather.devices.search')}
                        style={{ width: '100%', marginTop: '2px' }}
                    />
                </div>
            }
            style={{ maxHeight: '60vh', overflow: 'auto' }}
            size="small"
            itemLayout="horizontal"
            className="thin-scrollbar"
            dataSource={searchList}
            renderItem={(item: any) => {
                return (
                    <List.Item
                        style={{ padding: 20 }}
                        className={
                            selectedIdToShowDetail != undefined && selectedIdToShowDetail.id == item.id
                                ? 'selected-item'
                                : ''
                        }
                        actions={[<UnorderedListOutlined onClick={() => showDetails(item)} />]}
                    >
                        <List.Item.Meta
                            title={
                                <a
                                    onClick={() => {
                                        dispatch(setSelectedIdToShowDetails(item));
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                        }}
                                    >
                                        <div style={{ marginRight: '15px' }}>
                                            {item.alarm != 0 ? (
                                                <MinusCircleFilled style={{ color: 'red', fontSize: '20px' }} />
                                            ) : (
                                                <CheckCircleFilled style={{ color: 'green', fontSize: '20px' }} />
                                            )}
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                            <div style={{ flex: '70%' }}>
                                                <div>{item.name}</div>
                                                <div style={{ color: 'gray' }}>
                                                    {moment(item.ts).format('DD.MM.yyyy HH:mm')}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            }
                            //description={<div style={{padding:20}}>{item.serialNumber}</div>}
                        />
                    </List.Item>
                );
            }}
        />
    );
};

export default WeatherList;
