import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { NyRequestResolver, NySpinner, RESPONSE, geti18nText } from '@nybble/nyreact';
import { Col, Progress, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { calculateProgressGradient, calculatePercent, formLabelCard, cardslabel } from './cardsLabel';
import moment from 'moment';
import { CONSTANTS_REQ } from '../../../utils/Constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';
import DeviceWeatherChartDetail from '../weather/deviceWeatherChartDetail';

const WeatherDataTypeCardPreview: React.FC = (props: any) => {
    const [selectedDevice, setSelectedDevice] = useState<any>(undefined);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<any>(undefined);
    const { selectedIdToShowDetail, selectedWeatherDataType } = useSelector((state: RootState) => state.dashboard);

    useEffect(() => {
        if (selectedIdToShowDetail != undefined && selectedWeatherDataType != undefined) {
            setSelectedDevice(selectedIdToShowDetail);
            fetchData(selectedIdToShowDetail.id);
        } else {
            fetchData(undefined);
        }
    }, [selectedIdToShowDetail, selectedWeatherDataType]);

    async function fetchData(deviceId: any) {
        setLoading(true);
        console.log('map pins ', deviceId);
        if (deviceId == undefined) {
            let responseFetchPins = await NyRequestResolver.requestGet(CONSTANTS_REQ.DASHBOARD.WEATHER_SATE_MAPPINS);
            if (responseFetchPins && responseFetchPins.status == RESPONSE.OK) {
                if (Array.isArray(responseFetchPins.data)) {
                    let data = responseFetchPins.data[0];
                    console.log(data);
                    deviceId = data.id;
                    setSelectedDevice(data);
                }
            }
        }

        let result = await NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.DEVICE_CONSUMTION_WITH_LIMIT, {
            deviceId: deviceId,
        });
        if (result.status === RESPONSE.OK && result.data) {
            setLoading(false);
            let resultData: any = result.data;
            resultData = resultData.map((data: any) => {
                let item = cardslabel.find((card: any) => card.key === data.key);
                data.chartValue = data.value;
                delete data.value;
                if (item) data = Object.assign(data, item);
                return data;
            });

            if (selectedWeatherDataType != undefined) {
                const dataIndex = resultData.findIndex((x: any) => x.key === selectedWeatherDataType.key);
                if (dataIndex > -1) {
                    setData(resultData[dataIndex]);
                }
            }
        }
    }

    const WeatherDataTypeCardPreview = (card: any) => {
        return (
            <div
                style={{
                    display: 'flex',
                    padding: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    height: '100%',
                }}
            >
                <div style={{ position: 'relative', width: '100px', height: '150px' }}>
                    {card.icon}
                    <div style={{ position: 'absolute' }}>
                        <Progress
                            type="dashboard"
                            format={(percent) => ``}
                            strokeColor={calculateProgressGradient(card)}
                            gapDegree={120}
                            strokeLinecap={'square'}
                            percent={calculatePercent(card)}
                        />
                        <div style={{ display: 'flex', flexDirection: 'row', width: '250px' }}>
                            {card.showAlarm === true && card.lowerLimit && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                    }}
                                >
                                    <span>
                                        {geti18nText('weatherStateDetails.graph.alarm')}
                                        {':  '}
                                    </span>
                                    <ArrowDownOutlined
                                        style={{
                                            fontSize: '16px',
                                            color: '#108ee9',
                                            marginLeft: '3px',
                                        }}
                                    />
                                    <span>{card.lowerLimit + ' ' + card.unit}</span>
                                </div>
                            )}

                            {card.showAlarm === true && card.upperLimit && (
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        marginLeft: card.lowerLimit ? '30px' : '0px',
                                    }}
                                >
                                    <span>
                                        {geti18nText('weatherStateDetails.graph.alarm')}
                                        {':  '}
                                    </span>
                                    <ArrowUpOutlined
                                        style={{
                                            fontSize: '16px',
                                            color: '#a3264b',
                                            marginLeft: '3px',
                                        }}
                                    />
                                    <span>{card.upperLimit + ' ' + card.unit}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div></div>
                </div>
                <div style={{ marginLeft: 20 }}>
                    <div
                        style={{
                            fontSize: 16,
                            color: formLabelCard(card),
                            fontWeight: 'bold',
                            textAlign: 'center',
                        }}
                    >
                        {card.label}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div
                            style={{
                                fontSize: 28,
                                marginLeft: 10,
                                color: formLabelCard(card),
                                fontWeight: 'bold',
                            }}
                        >
                            {card.chartValue ? card.chartValue : 0}
                        </div>
                        <div style={{ fontSize: 18, fontWeight: 'bold', color: formLabelCard(card) }}>{card.unit}</div>
                    </div>
                </div>
            </div>
        );
    };

    return loading ? (
        <NySpinner />
    ) : (
        <>
            <Row gutter={24}>
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: 15, marginLeft: 25 }}>
                    {selectedDevice && (
                        <div style={{ fontSize: 16, width: '50%', fontWeight: 'bold' }}>
                            {selectedDevice.name +
                                ' - ' +
                                selectedDevice.serialNumber +
                                ' - ' +
                                moment(selectedDevice.ts).format('DD.MM.yyyy HH:mm')}
                        </div>
                    )}
                </div>
            </Row>
            <Row gutter={24}>
                <Col span={6}>{data && WeatherDataTypeCardPreview(data)}</Col>
                <Col span={18}>
                    <DeviceWeatherChartDetail
                        modal={false}
                        chartParmKey={selectedWeatherDataType?.chartKey}
                        selectedDevice={selectedDevice}
                    />
                </Col>
            </Row>
        </>
    );
};

export default WeatherDataTypeCardPreview;
