import { CloudServerOutlined, DragOutlined, LockOutlined, ThunderboltOutlined } from '@ant-design/icons';
import { geti18nText, NyRequestResolver, NyUtils, RESPONSE } from '@nybble/nyreact';
import { Button, Tooltip } from 'antd';
import L from 'leaflet';
import 'leaflet-area-select';
import 'leaflet-draw/dist/leaflet.draw.css';
import { GestureHandling } from 'leaflet-gesture-handling';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet/dist/leaflet.css';
import React, { useEffect, useRef, useState } from 'react';
import { FeatureGroup, MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../rootReducer';
import {
    filterReset,
    setMapSelectedBounds,
    addToWeatherFavorit,
    setWeatherFavoritList,
    setSelectedIdToShowDetails,
} from '../../slices/dashboardSlice';
import { CONSTANTS_REQ } from '../../utils/Constants';
import { GetEnum, GetEnumNameForValue } from '../../utils/Enums';
import 'leaflet-draw';
import { deviceWeatherStateDetailsOpen } from '../../slices/deviceWeatherStateDetailsReducer';

const WidgetWeatherMap: React.FC<any> = (props: any) => {
    L.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
    const [items, setItems] = useState<{ [index: string]: any }[]>([]);
    const [lightItems, setLightItems] = useState<{ [index: string]: any }[]>([]);
    const [energyItems, setEnergyItems] = useState<{ [index: string]: any }[]>([]);
    const [weatherStateItems, setWeatherStateItems] = useState<{ [index: string]: any }[]>([]);
    const [statusChanged, setStatusChanged] = useState<{ [index: string]: any }[]>([]);
    const { queryId, selectedDeviceGroupId, selectedWeatherDataType } = useSelector(
        (state: RootState) => state.dashboard
    );

    const mapRef = useRef<L.Map>();
    const mapFeatureRef = useRef<any>();
    const [layerId, setLayerId] = useState<any>(null);
    const dispatch = useDispatch();
    const {
        mapRefresh,
        mapBounds,
        mapPanTo,
        showWatermeters,
        showLights,
        showEnergymeters,
        showWeatherstate,
    } = useSelector((state: RootState) => state.dashboard);
    const mapOptions = {
        ref: mapRef,
        className: 'markercluster-map',
        center: [44.3057, 16.3366],
        zoom: 6,
        maxZoom: 18,
        gestureHandling: true,
        style: {
            height: '60vh',
        },
    };

    useEffect(() => {
        if (props.refreshFilter) {
            clearFilter();
        }
    }, [props.refreshFilter]);

    useEffect(() => {
        L.drawLocal.draw.toolbar.buttons.rectangle = geti18nText('map.draw.select.btn.rectangle');
        L.drawLocal.draw.handlers.rectangle.tooltip.start = geti18nText('map.draw.select.rectangle.tooltip.start');
        L.drawLocal.edit.toolbar.buttons.edit = geti18nText('map.draw.select.rectangle.edit.toolbar.buttons.edit');
        L.drawLocal.edit.toolbar.buttons.editDisabled = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.buttons.editDisabled'
        );
        L.drawLocal.edit.toolbar.buttons.remove = geti18nText('map.draw.select.rectangle.edit.toolbar.buttons.remove');
        L.drawLocal.edit.toolbar.buttons.removeDisabled = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.buttons.removeDisabled'
        );
        L.drawLocal.edit.toolbar.actions.save.title = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.actions.save.title'
        );
        L.drawLocal.edit.toolbar.actions.save.text = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.actions.save.text'
        );
        L.drawLocal.edit.toolbar.actions.cancel.title = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.actions.cancel.title'
        );
        L.drawLocal.edit.toolbar.actions.cancel.text = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.actions.cancel.text'
        );
        L.drawLocal.edit.toolbar.actions.clearAll.title = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.actions.clearAll.title'
        );
        L.drawLocal.edit.toolbar.actions.clearAll.text = geti18nText(
            'map.draw.select.rectangle.edit.toolbar.actions.clearAll.text'
        );
        L.drawLocal.edit.handlers.edit.tooltip.text = geti18nText(
            'map.draw.select.rectangle.edit.handlers.edit.tooltip.text'
        );
        L.drawLocal.edit.handlers.edit.tooltip.subtext = geti18nText(
            'map.draw.select.rectangle.edit.handlers.edit.tooltip.subtext'
        );
        L.drawLocal.edit.handlers.remove.tooltip.text = geti18nText(
            'map.draw.select.rectangle.edit.handlers.remove.tooltip.text'
        );
        L.drawLocal.draw.toolbar.actions.title = geti18nText('map.draw.select.rectangle.draw.toolbar.actions.title');
        L.drawLocal.draw.toolbar.actions.text = geti18nText('map.draw.select.rectangle.draw.toolbar.actions.text');
        L.drawLocal.draw.toolbar.finish.title = geti18nText('map.draw.select.rectangle.draw.toolbar.finish.title');
        L.drawLocal.draw.toolbar.finish.text = geti18nText('map.draw.select.rectangle.draw.toolbar.finish.text');

        fetchWeatheStatePins();
    }, [mapRefresh]);

    useEffect(() => {
        fetchWeatheStatePins();
    }, [queryId, selectedDeviceGroupId, selectedWeatherDataType]);

    useEffect(() => {
        if (mapRef.current && mapBounds != undefined) {
            let west, south, east, north;
            [west, south, east, north] = mapBounds.split(',').map(parseFloat);
            var bounds = new L.LatLngBounds(new L.LatLng(south, west), new L.LatLng(north, east));
            mapRef.current.flyToBounds(bounds);
        }
    }, [mapBounds, mapRef.current]);

    useEffect(() => {
        if (mapRef && mapRef.current !== null && mapPanTo != undefined) {
            mapRef.current?.flyTo(mapPanTo, 18);
        }
    }, [mapPanTo]);

    useEffect(() => {
        const interval = setInterval(() => {
            fetchPinStatusChange();
        }, 5 * 60 * 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const fetchPinStatusChange = () => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DASHBOARD.STATUSREFRESH).then((result) => {
            if (result && result.status == RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    setStatusChanged(result.data);
                }
            }
        });
    };

    function fetchWeatheStatePins() {
        let item: any = undefined;
        if (queryId != undefined && queryId > 0) {
            item = { category: queryId };
        }
        if (selectedDeviceGroupId != undefined) {
            item != undefined
                ? (item['deviceGroup'] = selectedDeviceGroupId)
                : (item = { deviceGroup: selectedDeviceGroupId });
        }
        if (selectedWeatherDataType != undefined) {
            item != undefined
                ? (item['key'] = selectedWeatherDataType.key)
                : (item = { key: selectedWeatherDataType.key });
        }
        NyRequestResolver.requestGet(CONSTANTS_REQ.DASHBOARD.WEATHER_SATE_MAPPINS, item).then((result) => {
            if (result && result.status == RESPONSE.OK) {
                if (Array.isArray(result.data)) {
                    setWeatherStateItems(result.data);
                    if (result.data.length <= 10) {
                        dispatch(setWeatherFavoritList(result.data));
                    }
                }

                var bounds: L.LatLngBounds | undefined = undefined;
                if (Array.isArray(result.data)) {
                    for (let pinData of result.data) {
                        if (bounds === undefined)
                            bounds = L.latLngBounds([pinData.lat, pinData.lng], [pinData.lat, pinData.lng]);
                        else bounds.extend([pinData.lat, pinData.lng]);
                    }
                }
                if (mapRef && mapRef.current && bounds != undefined) {
                    //dispatch(setMapBounds({ bounds: bounds.toBBoxString() }));
                    mapRef.current?.fitBounds(bounds);
                }
            }
        });
    }

    const clearFilter = () => {
        dispatch(filterReset());
        mapFeatureRef.current.eachLayer(function (layer: any) {
            mapFeatureRef.current.removeLayer(layer);
        });
    };

    const onDeleted = (e: any) => {
        setLayerId(null);
        dispatch(filterReset());
    };

    function removeLayers() {
        if (mapFeatureRef != null) {
            const drawnItems = mapFeatureRef.current._layers;
            if (Object.keys(drawnItems).length > 1) {
                Object.keys(drawnItems).forEach((layerid, index) => {
                    if (index > 0) {
                        return;
                    }
                    const layer = drawnItems[layerid];
                    mapFeatureRef.current.removeLayer(layer);
                });
            }
        }
    }

    const onCreated = (e: any) => {
        removeLayers();
        dispatch(
            setMapSelectedBounds({
                latNorthEast: e.layer._bounds._northEast.lat,
                lngNorthEast: e.layer._bounds._northEast.lng,
                latSouthWest: e.layer._bounds._southWest.lat,
                lngSouthWest: e.layer._bounds._southWest.lng,
            })
        );
    };
    const onEdited = (e: any) => {
        let layer: any = Object.values(e.layers._layers)[0];
        dispatch(
            setMapSelectedBounds({
                latNorthEast: layer._bounds._northEast.lat,
                lngNorthEast: layer._bounds._northEast.lng,
                latSouthWest: layer._bounds._southWest.lat,
                lngSouthWest: layer._bounds._southWest.lng,
            })
        );
    };

    return (
        <div>
            <MapContainer
                whenCreated={(mapInstance: any) => {
                    mapRef.current = mapInstance;
                }}
                ref={mapRef}
                {...(mapOptions as any)}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />

                {showWeatherstate && <WeatherStateDevicesPins items={weatherStateItems} statusChanged={[]} />}
                <FeatureGroup ref={mapFeatureRef}>
                    <EditControl
                        position="topright"
                        onEdited={onEdited}
                        onCreated={onCreated}
                        onDeleted={onDeleted}
                        draw={{
                            polyline: false,
                            rectangle: true,
                            circlemarker: false,
                            circle: false,
                            polygon: false,
                            marker: false,
                        }}
                    />
                </FeatureGroup>
            </MapContainer>
            <div
                style={{
                    position: 'absolute',
                    left: '10px',
                    bottom: '50px',
                    zIndex: 2000,
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
                }}
            >
                <Tooltip title={geti18nText('dashboard.widget.map.popup.clearfilter')}>
                    <Button onClick={clearFilter}>{geti18nText('dashboard.widget.map.clearfilter')}</Button>
                </Tooltip>
            </div>
            {/* <div
                style={{
                    position: 'absolute',
                    left: '10px',
                    bottom: '15px',
                    zIndex: 2000,
                    boxShadow: '0px 0px 4px 0px rgba(0,0,0,0.75)',
                }}
            >
                <Tooltip
                    title={
                        isStatic()
                            ? geti18nText('dashboard.widget.map.unlock')
                            : geti18nText('dashboard.widget.map.lock')
                    }
                >
                    <Button
                        onClick={() => lock(elementKey, !isStatic())}
                        icon={isStatic() ? <DragOutlined /> : <LockOutlined />}
                    />
                </Tooltip>
            </div> */}
        </div>
    );
};

interface iDevicePins {
    items: { [index: string]: any }[];
    statusChanged?: { [index: string]: string }[];
}

const WeatherStateDevicesPins: React.FC<iDevicePins> = ({ items, statusChanged = [] }) => {
    const {
        filterDevice,
        mapBounds,
        weatherStateActive,
        weatherStateInactive,
        weatherFavoritList,
        selectedWeatherDataType,
    } = useSelector((state: RootState) => state.dashboard);
    const dispatch = useDispatch();
    const [popUp, setPopUp] = useState<any>(null);
    function addPopup(e: any, item: any) {
        dispatch(setSelectedIdToShowDetails(item));
        setPopUp({
            key: new Date().getTime,
            position: e.latlng,
            item: item,
        });
    }

    function showDetailsWeatherState(device: any) {
        dispatch(deviceWeatherStateDetailsOpen({ record: device, visible: true }));
    }

    function filterItems() {
        if (filterDevice) {
            const fItems = items.filter((item) => item.id && item.id === filterDevice) || [];
            return fItems;
        } else if (weatherStateActive) {
            const fItems = items.filter((item) => item.alarmStatus != -48) || [];
            return fItems;
        } else if (weatherStateInactive) {
            const fItems = items.filter((item) => item.alarmStatus == -48) || [];
            return fItems;
        } else {
            return items;
        }
    }

    const addPinToList = (item: any) => {
        dispatch(addToWeatherFavorit(item));
        let widgetData = { pinned: weatherFavoritList };
        NyRequestResolver.requestPost(
            CONSTANTS_REQ.USER.WEATHER_WIDGETS,
            undefined,
            { widgets: JSON.stringify(widgetData) },
            false
        );
        setPopUp(null);
    };

    return (
        <React.Fragment>
            <MarkerClusterGroup>
                {filterItems().map((item: { [index: string]: any }, index: number) => (
                    <Marker
                        key={index}
                        icon={iconGenerator('weather', item, statusChanged, selectedWeatherDataType)}
                        position={[item.lat, item.lng]}
                        eventHandlers={{ click: (e) => addPopup(e, item) }}
                    ></Marker>
                ))}
                {popUp && (
                    <Popup key={popUp.key} offset={[0, -30]} position={popUp.position} onClose={() => setPopUp(null)}>
                        {<b>{popUp.item.name}</b>}
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.latitude')}: </b>
                        {popUp.item.lat}
                        <br />
                        <b>{geti18nText('dashboard.widget.map.popup.Longitude')}: </b>
                        {popUp.item.lng}
                        <br />
                        <Button onClick={() => showDetailsWeatherState(popUp.item)}>
                            {geti18nText('dashboard.widget.map.popup.details')}
                        </Button>
                        {(weatherFavoritList.length === 0 ||
                            !weatherFavoritList.map((item: any) => item.id).includes(popUp.item.id)) &&
                            items &&
                            items.length > 10 && (
                                <Button style={{ marginLeft: 5 }} onClick={() => addPinToList(popUp.item)}>
                                    {geti18nText('weather.widget.map.add.to.list')}
                                </Button>
                            )}
                    </Popup>
                )}
            </MarkerClusterGroup>
        </React.Fragment>
    );
};

export const iconGenerator = (
    type: 'gateway' | 'watermeter' | 'light' | 'energy' | 'weather',
    item: { [index: string]: any },
    statusChanged: { [index: string]: any }[],
    selectedWeatherDataType: any
) => {
    let color: string = '#FF0000';
    let icon = 'watermeter.png';
    let name = '';

    if (type == 'gateway') {
        icon = 'gtway.png';
        color = '#000000';
        name =
            item.mac != null
                ? item.mac
                : item.name
                ? item.name.substr(0, Math.min(item.name.length, 17)) + (item.name.length > 17 ? '...' : '')
                : '';
    } else if (type == 'watermeter') {
        name = item.name;

        const changedStatus = statusChanged.find(({ id }) => id === item.id);
        if (changedStatus != undefined) {
            if (!changedStatus.active) {
                color = '#FF0000';
            } else {
                color = '#00a900';
            }
        } else {
            if (!item.hasOwnProperty('alarmStatus')) {
                color = '#FF0000';
            } else if (item.alarmStatus == -48) {
                color = '#FF0000';
            } else {
                color = getSourceColor(item.source);
            }
        }
    } else if (type == 'light') {
        icon = 'light.png';
        color = '#FFD700';
        name =
            item.mac != null
                ? item.mac
                : item.name
                ? item.name.substr(0, Math.min(item.name.length, 17)) + (item.name.length > 17 ? '...' : '')
                : '';
    } else if (type == 'energy') {
        icon = 'energy.png';
        color = '#FFA500';
        name =
            item.name != null
                ? item.name.substr(0, Math.min(item.name.length, 17)) + (item.name.length > 17 ? '...' : '')
                : '';
    } else if (type == 'weather') {
        icon = 'weather.png';
        color = item.alarmStatus === GetEnum('ALARM_STATUS').INACTIVE ? '#EA0D01' : '#387e19';
        name =
            item.name != null
                ? item.name.substr(0, Math.min(item.name.length, 17)) + (item.name.length > 17 ? '...' : '') + ' '
                : '';
    }
    if (selectedWeatherDataType != undefined && item.alarmSetting?.value != undefined) {
        name += ' - ' + item.alarmSetting?.value + ' ' + item.alarmSetting?.unit;
    }

    return L.divIcon({
        className: 'custom-div-icon',
        html:
            "<div class='title' style='border-color: " +
            color +
            '; color: ' +
            color +
            "'>" +
            name +
            "</div><div style='background-color:" +
            color +
            ";' class='marker-pin'></div><img src='/" +
            icon +
            "' />",
        iconSize: [30, 60],
        iconAnchor: [15, 60],
    });
};

const getSourceColor = (source: number) => {
    switch (source) {
        case 200:
            return '#1890ff';
            break;
        case 300:
            return '#ff9900';
            break;
        case 400:
            return '#cc33ff';
            break;
        default:
            return '#00a900';
    }
};

export default WidgetWeatherMap;
