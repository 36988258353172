import React, { useState } from 'react';
import { cardslabel } from './cardsLabel';

import { setSelectedWeatherDataType } from '../../../slices/dashboardSlice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../rootReducer';

const WeatherDataTypeFilter = () => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useDispatch();
    const { selectedWeatherDataType } = useSelector((state: RootState) => state.dashboard);
    const selectKey = (item: any) => {
        let sel = item?.key;
        if (item?.key == selectedWeatherDataType?.key) {
            sel = undefined;
        }
        dispatch(setSelectedWeatherDataType(sel != undefined ? item : undefined));
    };

    return (
        <div className="weather-data-type-div">
            {cardslabel.map((item: any) => {
                return (
                    <>
                        <div
                            className={
                                item.key == selectedWeatherDataType?.key
                                    ? 'weather-data-type-div-item-select'
                                    : 'weather-data-type-div-item'
                            }
                            onClick={() => selectKey(item)}
                        >
                            {item.filter}
                        </div>
                    </>
                );
            })}
        </div>
    );
};

export default WeatherDataTypeFilter;
