import { geti18nText } from '@nybble/nyreact';

export const URL_PREFIX = process.env.NODE_ENV === 'development' ? 'http://localhost:10600/' : '/';
export const WS_URL_PREFIX = process.env.NODE_ENV === 'development' ? 'http://localhost:10600/' : '/';

export const APPLICATION_KEY = 'UIHUB';

export const DEFAULTS = {
    lang: 'hr',
};

export const CONSTANTS_REQ = {
    DASHBOARD: {
        STATISTIC: URL_PREFIX + 'device/dashboardStatistics',
        STATISTIC_LIGHTS: URL_PREFIX + 'device/dashboardStatisticsLights',
        STATOSTIC_ENERGY: URL_PREFIX + 'device/dashboardStatisticsEnergy',
        STATISTIC_WEATHER_STATE: URL_PREFIX + 'device/dashboardStatisticsWeatherState',
        DEVICELIST: URL_PREFIX + 'device/dashboardList',
        MAPPINS: URL_PREFIX + 'device/mapPins',
        LIGHT_MAPPINS: URL_PREFIX + 'device/mapLightPins',
        ENERGY_MAPPINS: URL_PREFIX + 'device/mapEnergyPins',
        WEATHER_SATE_MAPPINS: URL_PREFIX + 'device/mapWeathersPins',
        STATUSREFRESH: URL_PREFIX + 'device/statusRefresh',
        GATEWAYMAPPINS: URL_PREFIX + 'gateway/mapPins',
        UPDATECOORDINATES: URL_PREFIX + 'device/coordinates',
        UPDATECONTACTDATA: URL_PREFIX + 'customer/contactData',
        ALARMS: URL_PREFIX + 'device/dashboardAlarmList',
        WATERMETER_ALARMS: URL_PREFIX + 'device/watermeterAlarmList',
        ENERGYMETER_ALARMS: URL_PREFIX + 'device/energymeterAlarmList',
        LIGHTS_ALARMS: URL_PREFIX + 'device/lightsAlarmList',
        WEATHER_STATE_ALARMS: URL_PREFIX + 'device/weatherStateAlarmList',
        WS: WS_URL_PREFIX + 'ws/dashboard',
        GROUPCONSUMPTION: URL_PREFIX + 'deviceGroup/groupConsumption',
        MANUALCONSUMPTION: URL_PREFIX + 'device/manualConsumption',
        LIGHTS_LIST: URL_PREFIX + 'device/dashboardLightsList',
        ENERGY_METER_LIST: URL_PREFIX + 'device/dashboardEnergyList',
        WEATHER_METER_LIST: URL_PREFIX + 'device/dashboardWeatherList',
        ALARM_COUNT: URL_PREFIX + 'device/alarmCount',
    },
    INIT: URL_PREFIX + 'init',
    COUNTRY: {
        LIST: URL_PREFIX + 'country/list',
        EDIT: URL_PREFIX + 'country',
        SEARCH: URL_PREFIX + 'country/search',
    },
    DEVICE: {
        LIST: URL_PREFIX + 'device/deviceList',
        LIST_ALL: URL_PREFIX + 'device/deviceListAll',
        EDIT: URL_PREFIX + 'device',
        EDIT_LIGHT: URL_PREFIX + 'device/editLightDevice',
        EDIT_ENERGY: URL_PREFIX + 'device/editEnergyDevice',
        EDIT_WEATHER: URL_PREFIX + 'device/editWeatherDevice',
        SEARCH: URL_PREFIX + 'device/search',
        CHARTS_CONSUMPTION: URL_PREFIX + 'device/chartsConsumption',
        CHARTS_LIGHT_CONSUMPTION: URL_PREFIX + 'device/chartsLightConsumption',
        CHARTS_ENERGY_CONSUMPTION: URL_PREFIX + 'device/chartsEnergyConsumption',
        CHARTS_WEATHER_CONSUMPTION: URL_PREFIX + 'device/chartsWeatherConsumption',
        CHART_LIGHTS_VOLUME: URL_PREFIX + 'device/getLightChartData',
        VOLUMES: URL_PREFIX + 'device/deviceVolume',
        CONSUMPTION_FOR_TODAY_AND_CURRENT_WEEK_FOR_DEVICE:
            URL_PREFIX + 'device/consumptionForTodayAndCurrentWeekForDevice',
        TOP_CUSTOMERS: URL_PREFIX + 'device/topCustomers',
        TOP_CUSTOMERS_LIGHTS: URL_PREFIX + 'device/topCustomersLights',
        CONTACT_DATA_FOR_DEVICE: URL_PREFIX + 'device/contactDataForDevice',
        ADD_DEVICES_TO_GROUP: URL_PREFIX + 'device/addDevicesToGroup',
        DELETE_DEVICES_FROM_GROUP: URL_PREFIX + 'device/deleteDevicesFromGroup',
        PAYLOADHISTORY: URL_PREFIX + 'device/payloadHistory',
        PAYLOAD_LIGHT_HISTORY: URL_PREFIX + 'device/payloadLightHistory',
        PAYLOAD_ENERGY_HISTORY: URL_PREFIX + 'device/payloadEnergyHistory',
        PAYLOAD_WEATHER_STATE_HISTORY: URL_PREFIX + 'device/payloadWeatherStateHistory',
        DEVICE_ALARM_DESCRIPTION: URL_PREFIX + 'device/deviceAlarmDescription',
        IMPORT_CSV: URL_PREFIX + 'device/importCSV',
        INACTIVE_DEVICES_WITHOUT_TASK: URL_PREFIX + 'device/inactiveDevicesWithoutTask',
        GENERATE_TASKS: URL_PREFIX + 'device/generateTasks',
        SEARCH_FOR_TASKS: URL_PREFIX + 'device/searchForTasks',
        CURRENT_LIGHTS_CONSUMPTION_FOR: URL_PREFIX + 'device/currentDeviceConsumption',
        CURRENT_ENERGY_CONSUMPTION_FOR: URL_PREFIX + 'device/currentEnergyDeviceConsumption',
        CURRENT_WEATHER_STATE_CONSUMPTION_FOR: URL_PREFIX + 'device/currentWeatherStateDeviceConsumption',
        LIGHTS_CONSUMPTION_DATA: URL_PREFIX + 'device/consumptionData',
        ENERGY_CONSUMPTION_DATA: URL_PREFIX + 'device/energyConsumptionData',
        WEATHER_CONSUMPTION_DATA: URL_PREFIX + 'device/weatherConsumptionData',
        LIGHT_STREAM: URL_PREFIX + 'device/lightStream',
        DEVICE_CONSUMTION_WITH_LIMIT: URL_PREFIX + 'device/deviceConsumptionWithLimit',
    },
    DEVICE_TYPE: {
        LIST: URL_PREFIX + 'deviceType/list',
        EDIT: URL_PREFIX + 'deviceType',
        SEARCH: URL_PREFIX + 'deviceType/search',
    },
    WHITELABEL: {
        LIST: URL_PREFIX + 'whitelabel/list',
        EDIT: URL_PREFIX + 'whitelabel',
        DOMAIN: URL_PREFIX + 'whitelabel/domain',
        CUSTOMER: URL_PREFIX + 'whitelabel/customer',
    },
    CUSTOMER: {
        LIST: URL_PREFIX + 'customer/list',
        EDIT: URL_PREFIX + 'customer',
        SEARCH: URL_PREFIX + 'customer/search',
        CUSTOMER_ID: URL_PREFIX + 'customer/getCustomerId/',
    },
    USER: {
        LIST: URL_PREFIX + 'user/list',
        LIST_FOR_ALARM_SETTINGS: URL_PREFIX + 'user/listForAlarmSettings',
        EDIT: URL_PREFIX + 'user',
        USER_ALARM_LIST: URL_PREFIX + 'user/userAlarmsList',
        USER_ALARM_BY_USER_ID: URL_PREFIX + 'user/userAlarmByUserId',
        ALARM_EDIT: URL_PREFIX + 'user/userAlarm',
        HAS_IMPORT_EXPORT_MENU: URL_PREFIX + 'user/hasImportExportMenu',
        WIDGETS: URL_PREFIX + 'user/widgets',
        WEATHER_WIDGETS: URL_PREFIX + 'user/weatherWidgets',
    },
    ROLE: {
        SEARCH: URL_PREFIX + 'auth/role/search',
    },
    DEVICE_GROUP: {
        LIST: URL_PREFIX + 'deviceGroup/listGroup',
        LIST_ALL: URL_PREFIX + 'deviceGroup/listGroupAll',
        EDIT: URL_PREFIX + 'deviceGroup',
        SEARCH: URL_PREFIX + 'deviceGroup/search',
    },
    CUSTOMER_CONSUMPTION: {
        CHARTS_CONSUMPTION: URL_PREFIX + 'customerConsumption/chartsConsumption',
        CONSUMPTION_FOR_TODAY_AND_CURRENT_WEEK_FOR_DEVICE:
            URL_PREFIX + 'customerConsumption/consumptionForTodayAndCurrentWeekForDevice',
        CONTACT_DATA_FOR_DEVICE: URL_PREFIX + 'customerConsumption/contactDataForDevice',
        CHECK_SN: URL_PREFIX + 'customerConsumption/checkSN',
        CONSUMPTION_THRESHOLD: URL_PREFIX + 'customerConsumption/consumptionThreshold',
    },
    ENUMS: URL_PREFIX + 'enums/',
    DISK_FILE: {
        UPLOAD: URL_PREFIX + 'diskFile/upload',
        DOWNLOAD: URL_PREFIX + 'diskFile/download',
    },
    DEVICE_MODEL: {
        LIST: URL_PREFIX + 'deviceModel/list',
        EDIT: URL_PREFIX + 'deviceModel',
        SEARCH: URL_PREFIX + 'deviceModel/search',
    },
    TASK: {
        LIST: URL_PREFIX + 'task/list',
        EDIT: URL_PREFIX + 'task',
        LOG_LIST: URL_PREFIX + 'task/taskLogList/',
    },
    APPLICATION: {
        LIST: URL_PREFIX + 'application/list',
        EDIT: URL_PREFIX + 'application',
        SEARCH: URL_PREFIX + 'application/search',
    },
    APPLICATION_SETTINGS: {
        LIST: URL_PREFIX + 'applicationSettings/list',
        LIST_ALL: URL_PREFIX + 'applicationSettings/listAll',
        EDIT: URL_PREFIX + 'applicationSettings',
        SEARCH: URL_PREFIX + 'applicationSettings/search',
        SAVE: URL_PREFIX + 'applicationSettings/group',
        TETS_MAIL: URL_PREFIX + 'applicationSettings/testMail',
    },
    END_CUSTOMER: {
        LIST: URL_PREFIX + 'endCustomer/list',
        EDIT: URL_PREFIX + 'endCustomer',
        SEARCH: URL_PREFIX + 'endCustomer/search',
        DEVICES: URL_PREFIX + 'endCustomer/devices',
        CONTACT_DATA_END_CUSTOMER: URL_PREFIX + 'endCustomer/contactDataForEndCustomer',
    },
    GATEWAY: {
        LIST: URL_PREFIX + 'gateway/list',
        EDIT: URL_PREFIX + 'gateway',
        ALARMS: URL_PREFIX + 'gateway/gatewayAlarmsList',
        ALARM_DESCRIPTION: URL_PREFIX + 'gateway/gatewayAlarmDescription',
        CONTACT_DATA_FOR_GATEWAY: URL_PREFIX + 'gateway/contactDataForGateway',
        STATISTICS: URL_PREFIX + 'gateway/gatewayStatistic',
        UPDATECOORDINATES: URL_PREFIX + 'gateway/coordinates',
        UPDATE_ACTIVE_GATEWAY: URL_PREFIX + 'gateway/updateGatewayActive',
    },
    IMPORT_EXPORT: {
        LIST: URL_PREFIX + 'importExportLogs/list',
        EXPORT: URL_PREFIX + 'importExportLogs/exportData',
        UPDATE_LOG: URL_PREFIX + 'importExportLogs/updateLog',
        IMPORT_XML: URL_PREFIX + 'importExportLogs/importXml',
    },
    LIGHT_PARAMS: {
        LIST: URL_PREFIX + 'lightParams/list',
        EDIT: URL_PREFIX + 'lightParams',
    },
    ALARM_SETTINGS: {
        LIST_BY_TYPE: URL_PREFIX + 'alarmSettings/listByType',
        LIST_BY_HIERARCHY: URL_PREFIX + 'alarmSettings/listByHierarchy',
        SAVE_LIST_BY_TYPE: URL_PREFIX + 'alarmSettings/saveListByType',
        RESET_SETTINGS: URL_PREFIX + 'alarmSettings/resetSettings',
    },
    ALARM: {
        READ: URL_PREFIX + 'device/alarmReadById',
        READ_ALL: URL_PREFIX + 'device/alarmReadAll',
        READ_ALL_BY_CATEGORY: URL_PREFIX + 'device/alarmReadAllByCategory',
        WEATHER_USER_READ: URL_PREFIX + 'device/weatherUserAlarmReadById',
        WEATHER_USER_READ_ALL: URL_PREFIX + 'device/weatherUserAlarmReadAll',
        LIGHT_READ: URL_PREFIX + 'device/lightAlarmReadById',
        LIGHT_READ_ALL: URL_PREFIX + 'device/lightAlarmReadAll',
    },
    WEATHER_STATE_CATEGORY: {
        LIST: URL_PREFIX + 'deviceCategory/list',
        EDIT: URL_PREFIX + 'deviceCategory',
        SEARCH: URL_PREFIX + 'deviceCategory/search',
    },
};

export const DeviceCategoryType = {
    LIGHTS: 'LIGHTS',
    WATERMETER: 'WATERMETER',
    ENERGYMETER: 'ENERGYMETER',
    WEATHER: 'WEATHER',
};

export const EnvironmentType = {
    OUTSIDE: 1,
    INSIDE: 2,
};
export const AlarmSettingsType = {
    DEFAULT: -1,
    GLOBAL: 0,
    CUSTOMER: 1,
    DEVICE_GROUP: 2,
    DEVICE: 3,
};

export const WindDirectionDescription = (direction = 0, speed = 0) => {
    if (speed == 0) {
        return geti18nText('weatherStateDetails.windDirection.nodirection');
    }
    if (direction == 0 || direction == 360) return geti18nText('weatherStateDetails.windDirection.north');
    if (direction == 90) return geti18nText('weatherStateDetails.windDirection.east');
    if (direction == 180) return geti18nText('weatherStateDetails.windDirection.south');
    if (direction == 270) return geti18nText('weatherStateDetails.windDirection.west');
    if (direction > 0 && direction < 90) return geti18nText('weatherStateDetails.windDirection.northeast');
    if (direction > 90 && direction < 180) return geti18nText('weatherStateDetails.windDirection.southeast');
    if (direction > 180 && direction < 270) return geti18nText('weatherStateDetails.windDirection.southwest');
    if (direction > 270 && direction < 360) return geti18nText('weatherStateDetails.windDirection.northwest');

    return '-';
};

//export const WindDirectionDescription = WindDirection();

export const DeviceEnergySettingsByPhase = (phase1 = 'L1', phase2 = 'L2', phase3 = 'L3') => {
    return {
        title: geti18nText('energyDetails.graph.title'),
        'y-title': geti18nText('energyDetails.graph.y.value', [phase1]),
        'y-title1': geti18nText('energyDetails.graph.y.value', [phase2]),
        'y-title2': geti18nText('energyDetails.graph.y.value', [phase3]),
        color: '#7B3F00',
        color1: '#EE4B2B',
        color2: '#4c4cff',
        defaultColor: 'orange',
    };
};

export const DeviceEnergySettings = DeviceEnergySettingsByPhase();
