import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AlarmSettingsType, CONSTANTS_REQ, DeviceCategoryType } from '../../utils/Constants';
import { Row, Col, Input, Form, Tabs, InputNumber, Select, Divider, Checkbox } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import PayloadHistoryTable from './PayloadHistoryTable';
import AlarmsTable from './AlarmsTable';
import DeviceDetail from './DeviceDetail';
import { useDispatch } from 'react-redux';
import { selectedItemSet } from '../../slices/selectedItemSlice';
import { GetEnum } from '../../utils/Enums';
import { geti18nText, NyDataEdit, NySearchField, NySession } from '@nybble/nyreact';
import { isMobile } from 'react-device-detect';
import PayloadLightHistoryTable from './lights/PayloadLightHistoryTable';
import { deviceLightDetailsInit, deviceLightDetailsOpen } from '../../slices/deviceLightDetailsSlice';
import AlarmsLightTable from './lights/AlarmsLightTable';
import DeviceLightDetail from './lights/DeviceLightDetail';
import LightEdit from './lights/LightEdit';
import PayloadEnergyHistoryTable from '../dashboard/energy/PayloadEnergyHistoryTable';
import DeviceEnergyDetail from '../dashboard/energy';
import { deviceEnergyDetailsOpen } from '../../slices/deviceEnergyDetailsSlice';
import DeviceEnergyStatisticDetail from '../dashboard/energy/DeviceEnergyStatisticDetail';
import PayloadWeatherStateHistoryTable from '../dashboard/weather/PayloadWeatherStateHistory';
import DeviceWeatherDetail from '../dashboard/weather';
import AlarmsWeatherStateTable from '../dashboard/weather/AlarmsWeatherStateTable';
import { deviceWeatherStateDetailsOpen } from '../../slices/deviceWeatherStateDetailsReducer';
import DeviceWeatherStateStatisticDetail from '../dashboard/weather/DeviceWeatherStateStatisticDetail';
import AlarmSettingsIndex from '../alarm-settings';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CopyOutlined } from '@ant-design/icons';
import { v4 as uuidv4 } from 'uuid';

const { TabPane } = Tabs;

const DeviceEdit = (props: any) => {
    const dispatch = useDispatch();
    const [type, setType] = useState(props.addedData?.type ? props.addedData?.type : DeviceCategoryType.WATERMETER);
    const [editHeader, setEditHeader] = useState(geti18nText('device.edit.new'));
    const [loading, setLoading] = useState(false);
    const [dataForm, setDataForm] = useState([]);
    const phaseDescription = useRef();
    const [isCreate, setIsCreate] = useState(false);
    const [deviceStatus, setDeviceStatus] = useState<any>(undefined);
    const [selectedDeviceStatusValue, setSelectedDeviceStatusValue] = useState<any>(undefined);
    const [tabIndex, setTabIndex] = useState<any>(1);
    const [apiKeyChecked, setApiKeyChecked] = useState<any>(false);
    const [apiKey, setApiKey] = useState<any>(uuidv4());
    const [copied, setCopied] = useState<any>(false);
    const [form] = Form.useForm();
    const history = useHistory();
    const { id } = useParams<any>();

    useEffect(() => {
        const aTypes = GetEnum('DEVICE_STATUS');
        let device_status = [];
        for (var key in aTypes) {
            if (aTypes.hasOwnProperty(key)) {
                let ret = { value: undefined, label: undefined };
                ret.value = aTypes[key];
                ret.label = geti18nText('app.enum.DEVICE_STATUS.' + key);
                device_status.push(ret);
            }
        }

        setDeviceStatus(device_status);
    }, []);

    useEffect(() => {
        setSelectedDeviceStatusValue(0);
    }, [deviceStatus]);

    useEffect(() => {
        if (type == DeviceCategoryType.WATERMETER) {
            dispatch(selectedItemSet({ item: dataForm, refreshItem: 0 }));
        } else if (type == DeviceCategoryType.LIGHTS) {
            dispatch(deviceLightDetailsOpen({ record: { id: dataForm }, visible: false }));
        } else if (type == DeviceCategoryType.WEATHER) {
            dispatch(deviceWeatherStateDetailsOpen({ record: { id: dataForm }, visible: false }));
        } else {
            dispatch(
                deviceEnergyDetailsOpen({
                    record: { id: dataForm, phaseDescription: phaseDescription?.current },
                    visible: false,
                })
            );
        }
    }, [dataForm]);

    function setValues(dataForm: any) {
        if (type == DeviceCategoryType.LIGHTS)
            dispatch(deviceLightDetailsOpen({ record: { id: dataForm.id }, visible: false }));
        setEditHeader(dataForm.id);

        if (type == DeviceCategoryType.ENERGYMETER && dataForm.phaseDescription != null) {
            const phaseDescription = JSON.parse(dataForm.phaseDescription);
            phaseDescription.current = dataForm.phaseDescription;
            if (phaseDescription != null) {
                dataForm.phaseL1 = phaseDescription.L1;
                dataForm.phaseL2 = phaseDescription.L2;
                dataForm.phaseL3 = phaseDescription.L3;
            }
        }
        if (dataForm.apiKey) {
            setApiKey(dataForm.apiKey);
            setApiKeyChecked(true);
            dataForm.apiKeyCheckbox = true;
            dataForm.apiKeyPath = NySession.getSetting('api').host + 'api/device/' + dataForm.apiKey;
        }
        setDataForm(dataForm.id);
        delete dataForm.active;
        form.setFieldsValue(dataForm);
    }

    const onModalClose = () => {
        setApiKeyChecked(false);
        let uuid = uuidv4();
        setApiKey(uuid);
        form.resetFields();
        dispatch(deviceLightDetailsInit());
        setEditHeader(geti18nText('device.edit.new'));
    };

    const onChangeApiKey = (e: any) => {
        form.setFieldsValue({ apiKeyPath: NySession.getSetting('api').host + 'api/device/' + apiKey });
        setApiKeyChecked(e.target.checked);
    };

    const formApiKeyPath = () => {
        return NySession.getSetting('api').host + 'api/device/' + apiKey;
    };

    return (
        <NyDataEdit
            layout={isMobile ? 'vertical' : 'horizontal'}
            formProps={!isMobile ? { labelCol: { span: 7 }, wrapperCol: { span: 17 } } : {}}
            editHeader={editHeader}
            loading={loading}
            setLoading={setLoading}
            onModalClose={onModalClose}
            url={
                type == DeviceCategoryType.LIGHTS
                    ? CONSTANTS_REQ.DEVICE.EDIT_LIGHT
                    : type == DeviceCategoryType.ENERGYMETER
                    ? CONSTANTS_REQ.DEVICE.EDIT_ENERGY
                    : type == DeviceCategoryType.WEATHER
                    ? CONSTANTS_REQ.DEVICE.EDIT_WEATHER
                    : CONSTANTS_REQ.DEVICE.EDIT
            }
            setValues={setValues}
            width={'80%'}
            form={form}
            goBack={() => history.goBack()}
            paramsId={id}
            hideButtons={NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) && tabIndex == 1 ? false : true}
            setIsCreate={setIsCreate}
            {...props}
            normalize={(values: any) => {
                console.log(values);
                values.status = values.status === null ? 0 : values.status;
                values.deviceCategory = type;
                if (type == DeviceCategoryType.ENERGYMETER) {
                    const phaseDescription = { L1: values.phaseL1, L2: values.phaseL2, L3: values.phaseL3 };
                    values.phaseDescription = JSON.stringify(phaseDescription);
                }
                if (apiKeyChecked === false) {
                    values.apiKey = undefined;
                } else {
                    values.apiKey = apiKey;
                }
                return {
                    ...values,
                    deviceType:
                        values.deviceType != null && values.deviceType.constructor === Number
                            ? { id: parseInt(values.deviceType) }
                            : values.deviceType != null && values.deviceType.hasOwnProperty('id')
                            ? values.deviceType
                            : null,
                    deviceGroup:
                        values.deviceGroup != null && values.deviceGroup.constructor === Number
                            ? { id: parseInt(values.deviceGroup) }
                            : values.deviceGroup != null && values.deviceGroup.hasOwnProperty('id')
                            ? values.deviceGroup
                            : null,
                    deviceModel:
                        values.deviceModel != null && values.deviceModel.constructor === Number
                            ? { id: parseInt(values.deviceModel) }
                            : values.deviceModel != null && values.deviceModel.hasOwnProperty('id')
                            ? values.deviceModel
                            : null,
                    customer:
                        values.customer != null && values.customer.constructor === Number
                            ? { id: parseInt(values.customer) }
                            : values.customer != null && values.customer.hasOwnProperty('id')
                            ? values.customer
                            : null,
                    category:
                        values.category != null && values.category.constructor === Number
                            ? { id: parseInt(values.category) }
                            : values.category != null && values.category.hasOwnProperty('id')
                            ? values.category
                            : null,
                };
            }}
        >
            <Tabs
                type="card"
                onTabClick={(e) => {
                    setTabIndex(e);
                }}
            >
                <TabPane tab={geti18nText('customer.tab.general')} key="1">
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item name="id" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="commandUid" style={{ display: 'none' }}>
                                <Input />
                            </Form.Item>
                            <Form.Item label={geti18nText('device.edit.name')} name="name">
                                <Input
                                    disabled={
                                        NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? false : true
                                    }
                                />
                            </Form.Item>
                        </Col>
                        {type === DeviceCategoryType.WEATHER && (
                            <Col span={12}>
                                <Form.Item label={geti18nText('device.edit.category')} name="category">
                                    <NySearchField
                                        url={CONSTANTS_REQ.WEATHER_STATE_CATEGORY.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={
                                            NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? false : true
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.edit.deviceType')} name="deviceType">
                                <NySearchField
                                    url={CONSTANTS_REQ.DEVICE_TYPE.SEARCH}
                                    addedFilter={{
                                        field: 'deviceCategory',
                                        condition: 'equals',
                                        value: GetEnum('DEVICE_CATEGORY')[type],
                                    }}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    disabled={
                                        NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? false : true
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.edit.deviceModel')} name="deviceModel">
                                <NySearchField
                                    url={CONSTANTS_REQ.DEVICE_MODEL.SEARCH}
                                    map={{ id: 'id', label: 'name' }}
                                    searchBy="name"
                                    addedFilter={{
                                        field: 'deviceCategory',
                                        condition: 'equals',
                                        value: GetEnum('DEVICE_CATEGORY')[type],
                                    }}
                                    disabled={
                                        NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? false : true
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            {NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) && (
                                <Form.Item label={geti18nText('device.edit.customer')} name="customer">
                                    <NySearchField
                                        url={CONSTANTS_REQ.CUSTOMER.SEARCH}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                    />
                                </Form.Item>
                            )}
                            {!NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) && (
                                <Form.Item label={geti18nText('device.edit.deviceGroup')} name="deviceGroup">
                                    <NySearchField
                                        url={CONSTANTS_REQ.DEVICE_GROUP.SEARCH}
                                        addedFilter={{
                                            field: 'deviceCategory',
                                            condition: 'equals',
                                            value: GetEnum('DEVICE_CATEGORY')[type],
                                        }}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                        disabled={true}
                                    />
                                </Form.Item>
                            )}
                        </Col>
                        <Col span={12}>
                            {NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) && (
                                <Form.Item label={geti18nText('device.edit.deviceGroup')} name="deviceGroup">
                                    <NySearchField
                                        url={CONSTANTS_REQ.DEVICE_GROUP.SEARCH}
                                        addedFilter={{
                                            field: 'deviceCategory',
                                            condition: 'equals',
                                            value: GetEnum('DEVICE_CATEGORY')[type],
                                        }}
                                        map={{ id: 'id', label: 'name' }}
                                        searchBy="name"
                                    />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('device.edit.serialNumber')}
                                name="serialNumber"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Input
                                    disabled={
                                        NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? false : true
                                    }
                                />
                            </Form.Item>
                        </Col>
                        {type != DeviceCategoryType.LIGHTS && (
                            <Col span={12}>
                                <Form.Item label={geti18nText('device.edit.refId')} name="refId">
                                    <Input
                                        disabled={
                                            NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? false : true
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item
                                label={geti18nText('device.edit.eui')}
                                name="eui"
                                rules={[
                                    {
                                        required: true,
                                        message: geti18nText('app.default.required'),
                                    },
                                ]}
                            >
                                <Input
                                    disabled={
                                        NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? false : true
                                    }
                                />
                            </Form.Item>
                        </Col>

                        {type != DeviceCategoryType.LIGHTS && (
                            <Col span={12}>
                                <Form.Item label={geti18nText('device.edit.appeui')} name="appeui">
                                    <Input
                                        disabled={
                                            NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? false : true
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        )}
                    </Row>

                    {type != DeviceCategoryType.LIGHTS && (
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('device.edit.annualCertificationMark')}
                                    name="annualCertificationMark"
                                >
                                    <Input
                                        disabled={
                                            NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? false : true
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={geti18nText('device.edit.code')} name="code">
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}

                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.edit.comment')} name="comment">
                                <TextArea
                                    autoSize={{ minRows: 2, maxRows: 6 }}
                                    disabled={
                                        NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? false : true
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            {NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) && (
                                <Form.Item label={geti18nText('device.edit.status')} name="status">
                                    <Select defaultValue={selectedDeviceStatusValue} options={deviceStatus} />
                                </Form.Item>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.edit.lat')} name="lat">
                                <InputNumber
                                    decimalSeparator="."
                                    min={-90}
                                    max={90}
                                    style={{ width: '100%' }}
                                    disabled={
                                        NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? false : true
                                    }
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label={geti18nText('device.edit.lng')} name="lng">
                                <InputNumber
                                    decimalSeparator="."
                                    min={-180}
                                    max={180}
                                    style={{ width: '100%' }}
                                    disabled={
                                        NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN']) ? false : true
                                    }
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    {NySession.hasAnyRole(['ROLE_ADMIN']) && type === DeviceCategoryType.WEATHER && (
                        <Row gutter={24}>
                            <Col span={12}>
                                <Form.Item
                                    label={geti18nText('device.edit.api.key.checkbox')}
                                    name="apiKeyCheckbox"
                                    labelCol={{ span: 8 }}
                                    wrapperCol={{ span: 8 }}
                                    valuePropName={'checked'}
                                >
                                    <Checkbox
                                        onChange={(e) => {
                                            onChangeApiKey(e);
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    )}
                    {NySession.hasAnyRole(['ROLE_ADMIN']) && type === DeviceCategoryType.WEATHER && apiKeyChecked && (
                        <Row gutter={24}>
                            <Col style={{ flexDirection: 'row' }} span={12}>
                                <Form.Item label={geti18nText('device.edit.api.key')} name="apiKeyPath">
                                    <Input disabled />
                                </Form.Item>
                            </Col>
                            <Col span={6}>
                                <CopyToClipboard
                                    text={formApiKeyPath()}
                                    onCopy={() => {
                                        setCopied(true);
                                        setTimeout(() => {
                                            setCopied(false);
                                        }, 2500);
                                    }}
                                >
                                    <CopyOutlined style={{ fontSize: '18px', color: 'gray' }} />
                                </CopyToClipboard>
                                {copied ? (
                                    <span style={{ color: 'red', marginLeft: '15px', fontSize: '12px' }}>
                                        {geti18nText('device.edit.api.key.copy.to.clipboard')}
                                    </span>
                                ) : null}
                            </Col>
                        </Row>
                    )}

                    {type == DeviceCategoryType.ENERGYMETER && (
                        <>
                            {' '}
                            <Divider orientation="left">{geti18nText('device.edit.calculation.title')}</Divider>
                            <Row>
                                <Col span={14}>
                                    <Form.Item
                                        label={geti18nText('device.edit.calculationTimePeriod')}
                                        name="calculationTimePeriod"
                                    >
                                        <InputNumber
                                            disabled={
                                                NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'])
                                                    ? false
                                                    : true
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider orientation="left">{geti18nText('device.edit.phase_description.title')}</Divider>
                            <Row>
                                <Col span={8}>
                                    <Form.Item label={geti18nText('device.edit.phase_description.l1')} name="phaseL1">
                                        <Input
                                            disabled={
                                                NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'])
                                                    ? false
                                                    : true
                                            }
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label={geti18nText('device.edit.phase_description.l2')} name="phaseL2">
                                        <Input
                                            disabled={
                                                NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'])
                                                    ? false
                                                    : true
                                            }
                                        />
                                    </Form.Item>
                                </Col>

                                <Col span={8}>
                                    <Form.Item label={geti18nText('device.edit.phase_description.l3')} name="phaseL3">
                                        <Input
                                            disabled={
                                                NySession.hasAnyRole(['ROLE_ADMIN', 'ROLE_CUSTOMER_ADMIN'])
                                                    ? false
                                                    : true
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </>
                    )}
                </TabPane>
                <TabPane tab={geti18nText('device.tab.payloadHistory')} key="2" disabled={isCreate || loading}>
                    {type == DeviceCategoryType.LIGHTS ? (
                        <PayloadLightHistoryTable />
                    ) : type == DeviceCategoryType.ENERGYMETER ? (
                        <PayloadEnergyHistoryTable />
                    ) : type == DeviceCategoryType.WEATHER ? (
                        <PayloadWeatherStateHistoryTable />
                    ) : (
                        <PayloadHistoryTable />
                    )}
                </TabPane>

                <TabPane tab={geti18nText('device.tab.alarms')} key="3" disabled={isCreate || loading}>
                    {type == DeviceCategoryType.LIGHTS ? (
                        <AlarmsLightTable previewType="BY_DEVICE" />
                    ) : type == DeviceCategoryType.WEATHER ? (
                        <AlarmsWeatherStateTable previewType="BY_DEVICE" />
                    ) : (
                        <AlarmsTable previewType="BY_DEVICE" type={type} />
                    )}
                </TabPane>

                <TabPane tab={geti18nText('device.tab.graphs')} key="4" disabled={isCreate || loading}>
                    {type == DeviceCategoryType.LIGHTS ? (
                        <DeviceLightDetail />
                    ) : type == DeviceCategoryType.ENERGYMETER ? (
                        <DeviceEnergyStatisticDetail />
                    ) : type == DeviceCategoryType.WEATHER ? (
                        <DeviceWeatherStateStatisticDetail />
                    ) : (
                        <DeviceDetail />
                    )}
                </TabPane>
                {type == DeviceCategoryType.LIGHTS && (
                    <TabPane tab={geti18nText('device.tab.control')} key="5" disabled={isCreate || loading}>
                        <LightEdit></LightEdit>
                    </TabPane>
                )}
                {type == DeviceCategoryType.WEATHER && (
                    <TabPane tab={geti18nText('alarm.settings.edit.header')} key="6" disabled={isCreate || loading}>
                        <Row>
                            <Col span={24}>
                                <AlarmSettingsIndex type={AlarmSettingsType.DEVICE} deviceId={dataForm} />
                            </Col>
                        </Row>
                    </TabPane>
                )}
            </Tabs>
        </NyDataEdit>
    );
};

export default DeviceEdit;
