import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Card, Col, Form, InputNumber, Popover, Row, Slider, Switch } from 'antd';
import { NyUtils, geti18nText } from '@nybble/nyreact';
import { InfoCircleOutlined } from '@ant-design/icons';

const WidgetAlarmSettings = forwardRef(({ item }: any, ref) => {
    const [lowerLimit, setLowerLimit] = useState(item.lowerLimit);
    const [upperLimit, setUpperLimit] = useState(item.upperLimit);
    const [showAlarm, setShowAlarm] = useState(item.showAlarm);
    const [showSlider, setShowSlider] = useState(item.slider);
    const [range, setRange] = useState<any>([item.lowerLimit, item.upperLimit]);
    useImperativeHandle(
        ref,
        () => {
            return {
                method() {
                    const haveAlarm = showAlarm;
                    return {
                        lowerLimit: lowerLimit,
                        upperLimit: upperLimit,
                        showAlarm: haveAlarm,
                        key: item.key,
                    };
                },
            };
        },
        [lowerLimit, upperLimit, showAlarm]
    );

    const getMarks = () => {
        const mark: any = {};

        if (item.min) {
            mark[item.min] = item.min + '' + item.unit;
        }
        if (item.max) {
            mark[item.max] = item.max + '' + item.unit;
        }
        return mark;
    };

    const onChangeRangeSlider = (e: any) => {
        console.log(e);
        if (e != undefined && e.length == 2) {
            const lowerLimit = e[0];
            setLowerLimit(lowerLimit);
            const upperLimit = e[1];
            setUpperLimit(upperLimit);
            setRange(e);
        }
    };

    const onChangeLowerLimit = (e: any) => {
        setLowerLimit(e);
        setRange([e, upperLimit]);
    };

    const onChangeUpperLimit = (e: any) => {
        setUpperLimit(e);
        setRange([lowerLimit, e]);
    };
    const onChangeSlider = (e: any) => {
        if (e != undefined) {
            setUpperLimit(e);
        }
    };

    const onAlarmChange = (e: any) => {
        if (e != undefined) {
            setShowAlarm(e);
        }
    };

    const Description = () => {
        return (
            <div>
                <div style={{ display: 'inline', marginRight: '10px' }}>
                    {geti18nText('alarm.settings.edit.' + item.key.toLocaleLowerCase()) + ' (' + item.unit + ')'}
                </div>
                {NyUtils.getSelectedLanguage() == 'hr' && (
                    <Popover
                        content={
                            <div style={{ whiteSpace: 'pre-line' }}>
                                {geti18nText('alarm.settings.edit.' + item.key.toLocaleLowerCase() + '.desc')}
                            </div>
                        }
                    >
                        <InfoCircleOutlined style={{ color: '#91caff' }} />
                    </Popover>
                )}
            </div>
        );
    };

    return (
        <Card title={Description()} extra={<Switch checked={showAlarm} onChange={onAlarmChange} />} type="inner">
            {lowerLimit != undefined && showAlarm && showSlider && (
                <Row gutter={24}>
                    <Col offset={1} span={14}>
                        <Slider
                            range
                            marks={getMarks()}
                            value={range}
                            min={item?.min}
                            max={item?.max}
                            onChange={onChangeRangeSlider}
                        />
                    </Col>
                    <Col offset={1} span={4}>
                        <Form.Item label={geti18nText('alarm.settings.edit.widget.lowerLimit')} labelCol={{ span: 24 }}>
                            <InputNumber
                                min={item?.min}
                                max={item?.max}
                                value={lowerLimit}
                                onChange={onChangeLowerLimit}
                            ></InputNumber>
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item label={geti18nText('alarm.settings.edit.widget.upperLimit')} labelCol={{ span: 24 }}>
                            <InputNumber
                                min={item?.min}
                                max={item?.max}
                                value={upperLimit}
                                onChange={onChangeUpperLimit}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            )}
            {lowerLimit == undefined && showAlarm && showSlider && (
                <Row gutter={24}>
                    <Col offset={1} span={14}>
                        <Slider
                            marks={getMarks()}
                            value={upperLimit}
                            min={item?.min}
                            max={item?.max}
                            onChange={onChangeSlider}
                        />
                    </Col>
                    <Col offset={1} span={2}>
                        <Form.Item label={geti18nText('alarm.settings.edit.widget.limit')} labelCol={{ span: 24 }}>
                            <InputNumber
                                min={item?.min}
                                max={item?.max}
                                value={upperLimit}
                                onChange={onChangeUpperLimit}
                            ></InputNumber>
                        </Form.Item>
                    </Col>
                </Row>
            )}
            {showAlarm && !showSlider && (
                <Col offset={1} span={4}>
                    <Form.Item label={geti18nText('alarm.settings.edit.widget.limit')} labelCol={{ span: 24 }}>
                        <InputNumber
                            min={item?.min}
                            max={item?.max}
                            value={upperLimit}
                            onChange={onChangeUpperLimit}
                        ></InputNumber>
                    </Form.Item>
                </Col>
            )}
        </Card>
    );
});

export default WidgetAlarmSettings;
