import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Card, Statistic, Form } from 'antd';
import { LineChartOutlined, ThunderboltOutlined, WarningOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { geti18nText, NyRequestResolver, NySearchField, NyUtils, RESPONSE } from '@nybble/nyreact';
import { WindDirectionDescription } from '../../../../utils/Constants';

const { Title } = Typography;
interface IWindCard {
    value?: number;
    speed?: number;
}
const WindCard: React.FC<IWindCard> = ({ value = 0, speed = 0 }) => {
    const [directionValue, setDirectionValue] = useState<any>(value);
    const [speedValue, setSpeedValue] = useState(speed);
    const [width, setWidth] = useState(150);
    const [height, setHeight] = useState(200);

    useEffect(() => {
        setDirectionValue(value);
        setSpeedValue(speed);
    }, [value, speed]);

    const styles: any = {
        row: {
            alignItems: 'center',
            backgroundImage: 'linear-gradient(to bottom right, #87C1FF, #93c7ff)',
            width: '100%',
        },
        compassWrapper: {
            // alignItems: 'center',
            // justifyContent: 'center',
            position: 'relative',
            height: width,
            width: width,
        },
        angle: {
            color: '#fff',
            fontSize: height / 25,
            marginBottom: 30,
        },
        arrow: {
            position: 'absolute',
            width: 35,
            height: 35,
            // resizeMode: 'contain',
            // color: 'blue',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            margin: 'auto',
            transform: 'rotate(' + (180 + directionValue) + 'deg)',
            transition: 'transform 150ms ease',
        },
        compass_bg: {
            height: width,
            width: width,
            transition: 'transform 150ms ease',
            //  position: 'absolute',
            resizeMode: 'contain',
        },
        statistic: {
            margin: '8px',
        },
    };

    return (
        <React.Fragment>
            <Card style={styles.row} /*onClick={() => setParName(null)}*/>
                <Row>
                    <Col>
                        <div style={styles.compassWrapper}>
                            <img src={require('../../../../assets/img/arrow.png')} style={styles.arrow}></img>
                            <img
                                src={require('../../../../assets/img/compass.png')}
                                style={{ ...styles.compass_bg }}
                                //   style={[styles.compass_bg, { transform: [{ rotate: -90 + 'deg' }] }]}
                            ></img>
                        </div>
                    </Col>
                    <Col style={styles.statistic}>
                        <Statistic
                            title={geti18nText('weatherStateDetails.widget.title.windDirection')}
                            value={WindDirectionDescription(directionValue, speedValue)}
                            valueStyle={{
                                //color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                color: 'white',
                            }}
                        />
                        <Statistic
                            title={geti18nText('weatherStateDetails.widget.title.windSpeed')}
                            value={speedValue}
                            valueStyle={{
                                //color: parName == 'total_power' ? DeviceEnergySettings.defaultColor : '#808080',
                                fontSize: '14px',
                                fontWeight: 'bold',
                                color: 'white',
                            }}
                            suffix={'m/s'}
                        />
                    </Col>
                </Row>
            </Card>
        </React.Fragment>
    );
};

export default WindCard;
