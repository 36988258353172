import { Col, Row } from 'antd';
import ModalList from './ModalList';
import WidgetMap from './WidgetMap';
import { NyUtils } from '@nybble/nyreact';
import { APPLICATION_KEY } from '../../utils/Constants';
import React, { useEffect, useState } from 'react';
import { getUserCategoryType } from '../../utils/Enums';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { filterReset } from '../../slices/dashboardSlice';

const DashboardMain = (props: any) => {
    const [userCategoryType, setUserCategoryType] = useState<any>(undefined);
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        let userCategoryType = getUserCategoryType();
        if (userCategoryType != undefined && userCategoryType.length > 0 && userCategoryType.length === 1) {
            switch (userCategoryType[0]) {
                case 'WATERMETER':
                    history.push('waterMeterDashboard');
                    break;
                case 'LIGHTS':
                    history.push('lightsDashboard');
                    break;
                case 'ENERGYMETER':
                    history.push('energyDashboard');
                    break;
                case 'WEATHER':
                    history.push('weatherDashboard');
                    break;
            }
        }
        setUserCategoryType(userCategoryType);
        dispatch(filterReset());
    }, []);

    return (
        <>
            <Row>
                <Col span={24}>
                    {userCategoryType && (
                        <WidgetMap
                            elementKey="1A"
                            dataGrid={{
                                w: 12,
                                h: undefined,
                                x: 0,
                                y: 43,
                                minW: 1,
                                minH: 1,
                                moved: false,
                                static: true,
                            }}
                            showLightsOn={userCategoryType.includes('LIGHTS')}
                            showWatermeterOn={userCategoryType.includes('WATERMETER')}
                            showEnergyOn={userCategoryType.includes('ENERGYMETER')}
                            showWeatherStateOn={userCategoryType.includes('WEATHER')}
                        />
                    )}
                </Col>
            </Row>

            <ModalList />
        </>
    );
};

export default DashboardMain;
