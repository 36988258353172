import React, { useState, useEffect } from 'react';
import { Row, Col, Typography, Card, Statistic, Form, DatePicker } from 'antd';
import { LineChartOutlined, ThunderboltOutlined, WarningOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { geti18nText, NyRequestResolver, NySearchField, NyUtils, RESPONSE } from '@nybble/nyreact';
import { RootState } from '../../../rootReducer';
import { CONSTANTS_REQ, DeviceCategoryType } from '../../../utils/Constants';
import WindCard from './charts/wind';
import TempCard from './charts/tempWidget';
import WeatherChart from './charts/WeatherChart';
import AirCard from './charts/AirWidget';
import moment from 'moment';
import WeatherPolarChart from './charts/WeatherPolarChart';
import { Legend } from 'recharts';
import WeatherRainfallBarChart from './charts/WatherRainfallBarChart';

const { Title } = Typography;
const { RangePicker } = DatePicker;

type IWeatherStateResponse = {
    temp?: number;
    humidity?: number;
    uvintensity?: number;
    airPressure?: number;
    rainfall?: number;
    avgNoise?: number;
    maxNoise?: number;
    minNoise?: number;
    avgWindDirection?: number;
    maxWindDirection?: number;
    minWindDirection?: number;
    avgWindSpeed?: number;
    maxWindSpeed?: number;
    minWindSpeed?: number;
    solarRadiation?: number;
    pm_10?: number;
    pm_25?: number;
    lux?: number;
};

type IDataResponse = {
    converted?: string;
    barValue?: number;
};

const DeviceWeatherStateStatisticDetail = () => {
    const { record } = useSelector((state: RootState) => state.deviceWeatherStateDetails);
    const [loading, setLoading] = useState(false);
    const [snapshotData, setSnapshotData] = useState<IWeatherStateResponse>({});
    const [tempData, setTempData] = useState<any>(null);
    const [humidityData, setHumidityData] = useState<any>(null);
    const [airPressureData, setAirPressure] = useState<any>(null);
    const [rainfallData, setRainfallData] = useState<any>(null);
    const [solarRadiationData, setSolarRadiationData] = useState<any>(null);
    const [pm10Data, setPm10Data] = useState<any>(null);
    const [pm25Data, setPm25Dta] = useState<any>(null);
    const [noiseData, setNoiseData] = useState<any>(null);
    const [luxData, setLuxData] = useState<any>(null);
    const [soilTemperatureData, setSoilTemperatureData] = useState<any>(null);
    const [soilMoistureData, setSoilMoistureData] = useState<any>(null);
    const [sunshineHoursData, setSunshineHoursData] = useState<any>(null);
    const [UVIntensityData, setUVIntensityData] = useState<any>(null);
    const [period, setPeriod] = useState<any>({ id: 'last-24h', text: geti18nText('report.time_range.last24h') });
    const [spanPeriod, setSpanPeriod] = useState<any>({ dateFrom: null, dateTo: null });
    const [windData, setWidnData] = useState<any>(null);
    const periods = [
        { id: 'last-24h', text: geti18nText('report.time_range.last24h') },
        { id: 'current-week', text: geti18nText('report.time_range.current_week') },
        { id: 'last-week', text: geti18nText('report.time_range.last_week') },
        { id: 'current-month', text: geti18nText('report.time_range.current_month') },
        { id: 'current-year', text: geti18nText('report.time_range.current_year') },
        { id: 'span', text: geti18nText('report.time_range.span') },
    ];

    useEffect(() => {
        if (period != null && period.id != 'span') {
            fetchCurrentValue(record.id);
            fetchDataByParams(null);
        }
    }, [period]);

    useEffect(() => {
        if (period != null && period.id == 'span') {
            fetchCurrentValue(record.id);
            fetchDataByParams(spanPeriod);
        }
    }, [spanPeriod]);

    const fetchDataByParams = (spanDatePeriod: any) => {
        const dateFrom = spanDatePeriod != null && spanDatePeriod.dateFrom ? spanDatePeriod.dateFrom : null;
        const dateTo = spanDatePeriod != null && spanDatePeriod.dateTo ? spanDatePeriod.dateTo : null;
        fetchData(record.id, period.id, 'temp', setTempData, dateFrom, dateTo);
        fetchData(record.id, period.id, 'humidity', setHumidityData, dateFrom, dateTo);
        fetchData(record.id, period.id, 'air_pressure', setAirPressure, dateFrom, dateTo);
        fetchData(record.id, period.id, 'rainfall', setRainfallData, dateFrom, dateTo);
        fetchData(record.id, period.id, 'solar_radiation', setSolarRadiationData, dateFrom, dateTo);
        fetchData(record.id, period.id, 'pm_10', setPm10Data, dateFrom, dateTo);
        fetchData(record.id, period.id, 'pm_25', setPm25Dta, dateFrom, dateTo);
        fetchData(record.id, period.id, 'avg_noise', setNoiseData, dateFrom, dateTo);
        fetchData(record.id, period.id, 'uv_intensity', setUVIntensityData, dateFrom, dateTo);
        fetchData(record.id, period.id, 'wind', setWidnData, dateFrom, dateTo);
        fetchData(record.id, period.id, 'lux', setLuxData, dateFrom, dateTo);
        fetchData(record.id, period.id, 'sunshine_hours', setSunshineHoursData, dateFrom, dateTo);
        fetchData(record.id, period.id, 'soil_moisture', setSoilTemperatureData, dateFrom, dateTo);
        fetchData(record.id, period.id, 'soil_temperature', setSoilMoistureData, dateFrom, dateTo);
    };

    const fetchCurrentValue = (value: any) => {
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CURRENT_WEATHER_STATE_CONSUMPTION_FOR, {
            deviceId: value,
        }).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                const data: IWeatherStateResponse = response.data;
                setSnapshotData(data);
            }
        });
    };

    const fetchData = (deviceId: any, period: any, parName: any, setValue: any, dateFrom: any, dateTo: any) => {
        let data: any = {
            period: period,
            lang: NyUtils.getSelectedLanguage(),
            deviceId: deviceId,
            parName: parName,
        };

        if (dateFrom != null) {
            data['timestampFrom'] = dateFrom;
        }

        if (dateTo != null) {
            data['timestampTo'] = dateTo;
        }
        NyRequestResolver.requestGet(CONSTANTS_REQ.DEVICE.CHARTS_WEATHER_CONSUMPTION, data).then((response: any) => {
            if (response.status === RESPONSE.OK) {
                if (parName != 'wind') {
                    let data: IDataResponse[] = response.data;
                    data = data.filter((x) => x.barValue != null);
                    setValue(data);
                } else {
                    setValue(response.data);
                }
            }
        });
    };

    function periodOnChange(val: any) {
        console.log(val);
        setPeriod(val);
    }

    function disabledDate(current: any) {
        const rangeStart = moment().subtract(1, 'years');
        const rangeEnd = moment().endOf('day');
        return !(rangeStart.isSameOrBefore(current) && rangeEnd.isAfter(current));
    }

    function onRangeChange(dates: any) {
        if (dates != null) {
            const timestampFrom = dates[0] ? moment(dates[0]).valueOf() : null;
            const timestampTo = dates[1] ? moment(dates[1]).valueOf() : null;
            setSpanPeriod({ dateFrom: timestampFrom, dateTo: timestampTo });
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col span={12}>
                    <Form.Item label={geti18nText('report.time_range.select_period')}>
                        <NySearchField
                            value={period}
                            onChange={periodOnChange}
                            searchBy={'name'}
                            style={{ width: '90%' }}
                            options={periods}
                        />
                    </Form.Item>
                </Col>
                {period != undefined && period.id == 'span' && (
                    <Col>
                        <Row gutter={24} style={{ marginBottom: '10px' }}>
                            <Col span={24}>
                                <RangePicker
                                    disabledDate={disabledDate}
                                    showTime
                                    format="DD.MM.YYYY HH:mm:ss"
                                    onChange={onRangeChange}
                                />
                            </Col>
                        </Row>
                    </Col>
                )}
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                    <div className="box">
                        <Row>
                            <TempCard
                                temp={snapshotData?.temp}
                                airPressure={snapshotData?.airPressure}
                                uv={snapshotData?.uvintensity}
                                humidity={snapshotData?.humidity}
                                rainfall={snapshotData?.rainfall}
                                solarRadiation={snapshotData?.solarRadiation}
                            ></TempCard>
                        </Row>
                        <Row>
                            <WindCard
                                speed={snapshotData?.avgWindSpeed}
                                value={snapshotData?.avgWindDirection}
                            ></WindCard>
                        </Row>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                    <div className="box" style={{ height: '95%' }}>
                        <AirCard
                            pm_10={snapshotData?.pm_10}
                            pm_25={snapshotData?.pm_25}
                            avgNoise={snapshotData?.avgNoise}
                            lux={snapshotData?.lux}
                            solarRadiation={snapshotData?.solarRadiation}
                        ></AirCard>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <WeatherPolarChart
                        loading={loading}
                        data={windData}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={['avgWindDirection', 'avgWindSpeed']}
                        settings={{
                            title: geti18nText('weatherStateDetails.graph.title.wind'),
                            yLabel: geti18nText(''),
                            color: '#FF5733',
                        }}
                    />
                </Col>
                <Col xs={24} sm={24} md={24} lg={24} xl={6}>
                    <WeatherChart
                        loading={loading}
                        data={tempData}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={['temp']}
                        settings={{
                            title: geti18nText('weatherStateDetails.graph.title.temp'),
                            yLabel: geti18nText('weatherStateDetails.graph.y.value.temp'),
                            color: '#FF5733',
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <WeatherChart
                        loading={loading}
                        data={humidityData}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={['humidity']}
                        domain={[900, 1100]}
                        settings={{
                            title: geti18nText('weatherStateDetails.graph.title.humidity'),
                            yLabel: geti18nText('weatherStateDetails.graph.y.value.humidity'),
                            color: '#FF0000',
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <WeatherChart
                        loading={loading}
                        data={airPressureData}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={['airPressure']}
                        settings={{
                            title: geti18nText('weatherStateDetails.graph.title.airPressure'),
                            yLabel: geti18nText('weatherStateDetails.graph.y.value.airPressure'),
                            color: '#C70039',
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <WeatherRainfallBarChart
                        period={period.id}
                        params={['rainfall']}
                        deviceId={record.id}
                        data={rainfallData}
                        loading={loading}
                        title={period.text}
                    ></WeatherRainfallBarChart>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <WeatherChart
                        loading={loading}
                        data={solarRadiationData}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={['solarRadiation']}
                        settings={{
                            title: geti18nText('weatherStateDetails.graph.title.solarRadiation'),
                            yLabel: geti18nText('weatherStateDetails.graph.y.value.solarRadiation'),
                            color: '#7393B3',
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <WeatherChart
                        loading={loading}
                        data={pm10Data}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={['pm_10']}
                        settings={{
                            title: geti18nText('weatherStateDetails.graph.title.pm_10'),
                            yLabel: geti18nText('weatherStateDetails.graph.y.value.pm_10'),
                            color: '#00A36C',
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <WeatherChart
                        loading={loading}
                        data={pm25Data}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={['pm_25']}
                        settings={{
                            title: geti18nText('weatherStateDetails.graph.title.pm_25'),
                            yLabel: geti18nText('weatherStateDetails.graph.y.value.pm_25'),
                            color: '#00A36C',
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <WeatherChart
                        loading={loading}
                        data={noiseData}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={['noise']}
                        settings={{
                            title: geti18nText('weatherStateDetails.graph.title.noise'),
                            yLabel: geti18nText('weatherStateDetails.graph.y.value.noise'),
                            color: '#003666',
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <WeatherChart
                        loading={loading}
                        data={UVIntensityData}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={['uv_intensity']}
                        settings={{
                            title: geti18nText('weatherStateDetails.graph.title.UVIntensity'),
                            yLabel: geti18nText('weatherStateDetails.graph.y.value.UVIntensity'),
                            color: '#8e43e7',
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <WeatherChart
                        loading={loading}
                        data={luxData}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={['lux']}
                        settings={{
                            title: geti18nText('weatherStateDetails.graph.title.lux'),
                            yLabel: geti18nText('weatherStateDetails.graph.y.value.lux'),
                            color: '#7393B3',
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <WeatherChart
                        loading={loading}
                        data={sunshineHoursData}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={['sunshineHours']}
                        settings={{
                            title: geti18nText('weatherStateDetails.graph.title.sunshine_hours'),
                            yLabel: geti18nText('weatherStateDetails.graph.y.value.sunshine_hours'),
                            color: '#7393B3',
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <WeatherChart
                        loading={loading}
                        data={soilTemperatureData}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={['soilTemperature']}
                        settings={{
                            title: geti18nText('weatherStateDetails.graph.title.soil_temperature'),
                            yLabel: geti18nText('weatherStateDetails.graph.y.value.soil_temperature'),
                            color: '#FF5733',
                        }}
                    />
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={6}>
                    <WeatherChart
                        loading={loading}
                        data={soilMoistureData}
                        title={period.text}
                        deviceId={record.id}
                        period={period.id}
                        showYLabel={true}
                        params={['soilMoisture']}
                        settings={{
                            title: geti18nText('weatherStateDetails.graph.title.soil_moisture'),
                            yLabel: geti18nText('weatherStateDetails.graph.y.value.soil_moisture'),
                            color: '#FF0000',
                        }}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default DeviceWeatherStateStatisticDetail;
